////****    COLORS    ****////

$white: #fff;
$dark: #25223b;
$black: #000;
$grey: #707070;
$dark-blue: #5D717A;
$gold: #E3DBD2;
$red: #e83a5c;
$blue: #009fe3;

$registration-background: $white;

$primary-color: $grey;
$secondary-color: $black;
$third-color: #57001B;


////****    TYPOGRAPHY    ****////

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Rockwell-Bold';
  src: url("../../fonts/Rockwell-bold.otf") format("opentype");
}

@font-face {
  font-family: 'Rockwell-Regular';
  src: url("../../fonts/Rockwell.otf") format("opentype");
}


////****    FONT FAMILY    ****////
$font-family-brandon: 'Jost', sans-serif;
$font-family-rockwell: 'Rockwell-Regular', sans-serif;
$font-family-rockwell-bold: 'Rockwell-Bold', sans-serif;
$font-family-arial: 'Arial', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-primary: $font-family-brandon;
$font-family-secondary: $font-family-rockwell-bold;


////****    FONT WEIGHT    ****////
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-normal: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;


////****    HEADINGS    ****////


////****    PARAGRAPHS    ****////
$paragraphs-font-family: $font-family-primary;
$paragraphs-font-size: 16px;
$paragraphs-line-height: 20px;
$paragraphs-color: $grey;


////****    LINKS    ****////
$links-hover-color: $black;

////****    BUTTONS    ****////
$buttons-height: 44px;
$buttons-border: none;
$buttons-padding: 0 15px;
$buttons-family: $font-family-brandon;
$buttons-font-size: 14px;
$buttons-line-height: 20px;
$buttons-shadow: 0 2px 7px #00000040;
$buttons-radius: 40px;

////****    LISTS    ****////
$list-dots-size: 4px;
$list-dots-top-position: 8px;
$list-dots-radius: 100%;


////****    BREAKPOINTS    ****////

$screen-xlg-min: 1920px;
$screen-lg-max: $screen-xlg-min - 1px;
$screen-lg-min: 1200px;
$screen-md-max: $screen-lg-min - 1px;
$screen-md-min: 992px;
$screen-sm-max: $screen-md-min - 1px;
$screen-sm-min: 768px;
$screen-xs-max: $screen-sm-min - 1px;


////****    LOADER    ****////
$btn-loading-background: $white;


////****    HEADER    ****////

$header-background-color: $white;
$header-text-color: $black;
$header-text-hover-color: $black;
$header-disconnect-font-size: 11px;
$header-disconnect-text-color: $black;
$header-disconnect-text-hover-color: $black;
$header-disconnect-text-color-mobile: $black;

// Header switch language
$switch-language-font-family: $font-family-primary;
$switch-language-color: $white;
$switch-language-font-size: 14px;
$switch-language-weight: 700;

//header
$header-max-width: 1200px;
$burger-btn-text-color: $black;
$burger-btn-color: $black;
$burger-btn-color-hover: $black;
$btn-inscription-mobile-background: $black;
$btn-inscription-mobile-color: $black;
//side nav
$sidenav-mobile-background-color: $black;
$sidenav-mobile-cross-color: $white;
$sidenav-mobile-cross-color-hover: $white;

// Contact
$contact-background-color: $gold;
$contact-title-color: $white;
$contact-background-title-color: #00205C;
$contact-background-text-color: $white;
$contact-text-color: $primary-color;
$contact-link-color-hover: $secondary-color;


////****    FOOTER    ****////

$footer-background-color: $black;
$footer-link-color: $white;
$footer-link-color-hover: $red;
$footer-link-font-family: $font-family-arial;
$footer-font-size: 14px;
$footer-font-weight: 500;

// Sub Footer brand
$sub-footer-brand-background-color: $white;


////****    RGPD POPIN    ****////

$rgpd-background-color: #000000;
$rgpd-text-color: $white;
$rgpd-close-background-color: #000000;
$rgpd-close-cross-color: $white;


////****    MODAL    ****////

$modal-header-border-color: $grey;
$modal-header-text-color: $dark-blue;
$modal-header-text-font-family: $font-family-primary;
$modal-title-color: $black;
$modal-title-font-family: $font-family-rockwell-bold;
$modal-text-title-background: $secondary-color;
$modal-text-title-color: $white;
$modal-text-title-font-family: $font-family-secondary;
$hebergement-hotel-name-background: rgba(255, 255, 255, 80%);

////****    BANDEAU TITLE    ****////
$bandeau-title: 21px 0 21px;
