form {

  .form-item {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  // Label

  label {
    display: block;
    font-family: $form-components-font-family;
    font-size: $form-label-font-size;
    line-height: $form-label-line-height;
    color: $form-components-color;
    padding-bottom: 6px;
  }

  // Input

  select, .vs__dropdown-toggle, input[type="text"], input[type="password"], input[type="email"], input[type="tel"], input[type="date"], input[type="number"], textarea, input[type="search"] {
    width: 100%;
    height: $form-input-height;
    padding: 0 10px;
    border-radius: $form-input-radius;
    border: $form-input-border;
    font-family: $form-components-font-family;
    font-size: $form-input-font-size;
    line-height: $form-input-line-height;
    font-style: $form-input-font-style;
    color: $form-components-color;

    &::placeholder {
      font-weight: normal;
      font-family: $form-components-font-family;
      font-size: $form-input-font-size;
      line-height: $form-input-line-height;
      color: $form-components-color;
    }
  }

  input[type="date"]::-webkit-datetime-edit-text,
  input[type="date"]::-webkit-datetime-edit-month-field,
  input[type="date"]::-webkit-datetime-edit-day-field,
  input[type="date"]::-webkit-datetime-edit-year-field {
    color: $form-components-color !important;
  }

  // Textarea

  textarea {
    resize: none;
    height: $form-textarea-height;
    padding: 5px 10px;
    font-family: $form-components-font-family;
    font-size: $form-input-font-size;
    line-height: $form-input-line-height;
    font-style: $form-input-font-style;
    color: $form-components-color;

    &::placeholder {
      font-family: $form-components-font-family;
      font-style: normal !important;
      font-size: $form-input-font-size;
      color: $form-components-color;
    }
  }

  // Radio & Checkbox

  .form-item-choice-container {
    display: flex;

    @media (max-width: $screen-xs-max) {
      margin-left: 0;
    }

    &.form-item-choice {
      &-in-line {
        flex-direction: row;

        .form-item-choice {
          margin-left: 25px;

          &:first-child {
            margin-left: 0;
          }
        }
      }

      &-in-column {
        flex-direction: column;

        .form-item-choice {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .form-type-radio, .form-type-checkbox, .form-item-choice {

    label {
      display: block;
      position: relative;
      font-family: $form-components-font-family;
      cursor: pointer;
      font-size: $form-radio-checkbox-label-font-size;
      line-height: $form-radio-checkbox-label-line-height;
      color: $form-components-color;
      padding: 0 0 0 25px;

      &:before, &:after {
        content: "";
        position: absolute;
        left: 0;
        top: -3px;
      }

      &:before {
        border: $form-radio-checkbox-before-border;
        border-radius: 100%;
      }

      &:after {
        border-radius: 100%;
        background: $form-radio-checkbox-active-background;
        display: none;
      }
    }

    input {
      display: none;

      &:checked ~ label:after {
        display: block;
      }

      &[disabled="disabled"] ~ label {
        color: $form-radio-checkbox-color-disabled;
        cursor: default;

        &:before, &:after {
          border: 1px solid $form-radio-checkbox-color-disabled;
        }

        &:after {
        }
      }
    }
  }

  .form-type-radio, .form-item-choice {
    label {
      &:before, &:after {
        border-radius: 100%;
      }

      &:before {
        width: $form-radio-before-size;
        height: $form-radio-before-size;
      }

      &:after {
        width: $form-radio-after-size;
        height: $form-radio-after-size;
        left: 3px;
        top: 0;
      }
    }

    input {

      &:checked ~ label:before {
        border: $form-radio-active-border;
      }
    }
  }

  .form-type-checkbox {
    margin-left: 0;

    label {

      &:before, &:after {
        border-radius: $form-checkbox-radius;
        top: -2px;
      }

      &:before {
        width: $form-checkbox-before-size;
        height: $form-checkbox-before-size;
      }

      &:after {
        width: $form-checkbox-after-size;
        height: $form-checkbox-after-size;
      }

      .picto {
        display: none;
        position: absolute;
        justify-content: center;
        align-items: center;
        left: 0;
        top: -2px;
        width: $form-checkbox-after-size;
        height: $form-checkbox-after-size;
        z-index: 3;

        svg {
          width: $form-checkbox-picto-size;
          height: auto;
          margin-top: -3px;
        }
      }
    }

    input {
      &:checked ~ label .picto {
        display: flex;
      }

      &:not(:checked) ~ label .picto {
        display: none;
      }
    }
  }


  select, .vs__dropdown-toggle {
    cursor: pointer;

    option:first-child {
      &.hide {
        display: none;
      }
    }
  }

  .vs__dropdown-toggle {
    border-radius: 0;
    padding: 0;
    display: flex;
    appearance: none;
    background: none;
    white-space: normal;

    .vs__selected-options {
      height: 38px;
      display: flex;
      flex-basis: 100%;
      flex-grow: 1;
      flex-wrap: wrap;
      padding: 0 2px;
      position: relative;

      input, .vs__selected {
        font-family: $font-family-primary;
        font-weight: normal;
        font-size: 14px;
        padding: 0 10px;
        margin: 0;
        height: 38Px;
        border: none;
        appearance: none;
        outline: none;
      }

    }

    .vs__actions {
      cursor: pointer;

      svg {
        path {
          fill: $black;

        }
      }
    }
  }

  .vs__dropdown-menu {
    li {
      padding: 5px 10px;
      font-family: $font-family-primary;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .help-text {
    font-family: $form-components-font-family;
    font-size: 10px;
    line-height: 10px;
    padding-top: 5Px;
  }

  .error-message, .field-errors-list li {
    font-family: $form-components-font-family;
    color: $form-components-error-color;
    font-size: 10px;
    line-height: 10px;
    padding-top: 7Px;
    display: block;
    width: 100%;

    &.form-error-message {
      text-align: center;
    }
  }
}
