.payment{
  padding:0;
  &-wrapper{
    height: calc(100vh - 500px);
    min-height: 390px;
    display: flex;
    align-items: center;
    padding: 0 40px;
    @media (max-width:$screen-xs-max) {
      padding: 0 ;
    }
  }
  &-title{
    background: $payment-bandeau-background-color;
    color:$payment-bandeau-text-color;
  }


  &-page-cta{
    margin-top: 20px;
    a{
      padding-top: 2px;
    }
  }
  &.confirmation{
    .payment-wrapper{
      height: calc(100vh - 500px);
      min-height: 470px;
    }
    .payment-content{
      padding: 50px 0 0;
    }

  }
}
