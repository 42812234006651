/* GENERAL STYLES */
:root {
  //use vh propretie for login page
  --vhLogin: 100vh;
  //use that for before element img infos-pratiques
  --right: 0%;
  --left: 0%;
}


* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: $white;
  margin: 0;

  &.no-scroll {
    position: fixed;
    overflow: hidden;
    @media (min-width: 768px) {
      position: relative;
      overflow: auto;
    }
  }

  &.toolbar-fixed {
    header {
      top: 42px;
    }

    &.toolbar-tray-open {
      header {
        top: 85px;
      }
    }
  }

  &.show-modal {
    overflow: hidden;
  }

  .overlay-modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 501;
  }

  &.overlay-show {
    .overlay {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 499;
    }
  }

  .layout-container {
    .header-cta-inscription {
      a {
        &.inscription {
          display: flex !important;
          height: 33px;
          font-size: 14px;
          font-weight: bold;
          box-shadow: 0 2px 7px #00000040;
          @media only screen and (max-width: $screen-md-max) {
            box-shadow: unset;
          }
          @media only screen and (max-width: $screen-xs-max) {
            box-shadow: unset;
            min-height: 33px;
            max-height: 33px;
            height: 33px;
            font-size: 12px;
          }

          &:hover {
            height: 33px;
            border: none !important;
          }

        }
      }
    }
  }

  &.with-switch-language {
    header {
      position: absolute;
      background: unset;

      nav, nav > div {
        justify-content: flex-end;
      }
    }
  }
}


/*---- HEADINGS ----*/

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 .title {
  margin: 0;
  padding: 0;
  font-weight: $font-weight-bold;
  letter-spacing: 1px;
  font-family: $font-family-montserrat;
}


.title {
  font-size: 26px;
  font-weight: $font-weight-bold;
  line-height: 30px;
}

h1, .h1 {
  font-size: 26px;
  line-height: 30px;
  @media (max-width: $screen-xs-max) {
    font-size: 18px;
    line-height: 22px;
  }

}

h2, .h2 {
  font-size: 26px;
  line-height: 30px;
  @media (max-width: $screen-xs-max) {
    font-size: 18px;
    line-height: 22px;
  }
}

h3, .h3 {
  font-size: 20px;
  line-height: 25px;
  @media (max-width: $screen-xs-max) {
    font-size: 16px;
    line-height: 22px;
  }
}

h4, .h4 {
  font-size: 19px;
  line-height: 22px;
}

h5, .h5 {
  font-size: 16px;
  line-height: 20px;
}

a {
  text-decoration: none;
  font-family: $paragraphs-font-family;
  font-weight: $font-weight-regular;
  font-size: $paragraphs-font-size;
  line-height: $paragraphs-line-height;
  color: $paragraphs-color;

  &:hover {
    color: $links-hover-color !important;
  }

  &.underline-link {
    text-decoration: underline $paragraphs-color;

    &:hover {
      text-decoration: underline $links-hover-color;
    }
  }
}

p {
  margin: 0;
  font-family: $paragraphs-font-family;
  font-weight: $font-weight-regular;
  color: $paragraphs-color;
  font-size: $paragraphs-font-size;
  line-height: $paragraphs-line-height;

  sup {
    font-size: 10px;
  }

  &.intro {
    font-size: 17px;
    line-height: 19px;
    font-weight: $font-weight-normal;
  }

  &.annotation {
    font-size: 12px;
    line-height: 14px;
  }
}

ul {
  li {
    position: relative;
    font-family: $paragraphs-font-family;
    font-weight: $font-weight-regular;
    color: $paragraphs-color;
    font-size: $paragraphs-font-size;
    line-height: $paragraphs-line-height;
  }

  &.with-dots {
    li {
      padding-left: 15px;

      &:before {
        position: absolute;
        content: '';
        width: $list-dots-size;
        height: $list-dots-size;
        border-radius: $list-dots-radius;
        top: $list-dots-top-position;
        left: 0;
        background: $paragraphs-color;
      }
    }

  }
}

.icon {
  &.hidden {
    display: none;
  }

  &-plus {
    &:before {
      content: '+';
    }

  }

  &-minus {
    &:before {
      content: '-';
    }

  }
}

// LIST-STYLE-TYPE
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* TEXT */
.bold {
  font-weight: bold !important;

  a {
    font-weight: bold !important;
  }
}

.italic {
  font-style: italic;
}

.layout-container {
  &.hide {
    display: none;
  }
}


.block-title {
  position: relative;
  width: 100%;
  background: $background-block-title-homepage;
  @media (max-width: $screen-xs-max) {
    position: sticky;
    top: 96px;
    z-index: 10;
  }

  h1, h2 {
    text-align: center !important;
    text-align-last: center !important;
    text-transform: uppercase;
    padding: $bandeau-title;
    color: $color-text-block-title-homepage;
    @media (max-width: $screen-xs-max) {

    }
  }

  &.with-underline {
    background: none !important;

    h1, h2 {
      color: $white;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -10px;
        width: 50px;
        height: 2px;
        background: $red;
        transform: translateX(-50%);
        color: $color-text-block-title-homepage !important;
      }
    }
  }
}

.page-registration .block-title {
  @media (max-width: $screen-xs-max) {
    position: relative;
    top: unset;
    z-index: 10;
  }
}

.container-title {
  background: $primary-color;
  text-align: center;
  padding: 20px 0;
  margin-top: -1px;

  & > h1 {
    text-transform: uppercase;
    padding: 0 20px;
    color: $white;
  }
}

.hidden-mobile {
  display: flex;
  width: 100%;
  @media (max-width: $screen-xs-max) {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.hidden-desktop {
  display: none;
  width: 100%;
  @media (max-width: $screen-xs-max) {
    display: flex;
  }

  img {
    width: 100%;
    height: auto;
  }
}
