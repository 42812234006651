.rooming-block {
  margin-top: 80px;
  border-radius: $form-block-radius;

  .registration-block-header {
    border-top-right-radius: $form-block-radius;
    border-top-left-radius: $form-block-radius;

    &-close {
      display: none !important;
    }
  }

  .registration-block-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .form-item-wantRooming {
      align-items: center;


      > label {
        font-weight: bold;
        padding-bottom: 20px;
      }
    }


    &.no_more_participants {
      padding: 30px 40px 0 40px;
      @media only screen and (max-width: $screen-xs-max) {
        padding: 30px 20px 0 20px;
      }
    }

    .room-form-item {
      &-container {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-bottom: 40px;
        @media only screen and (max-width: $screen-xs-max) {
          width: 100%;
          margin-bottom: 30px;
        }

        &:first-child, &:nth-child(2) {
          margin-top: 20px;
          @media only screen and (max-width: $screen-xs-max) {
            margin-top: 0;
          }
        }

        &:nth-child(odd) {
          padding-right: 15px;
          @media only screen and (max-width: $screen-xs-max) {
            padding-right: 0;
          }
        }

        &:nth-child(even) {
          padding-left: 15px;
          @media only screen and (max-width: $screen-xs-max) {
            padding-left: 0;
          }
        }
      }

      &-section {
        flex-grow: 1;
        display: flex;

        .registration-block {
          border-radius: $form-block-radius;
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          &-header {
            border-top-left-radius: $form-block-radius;
            background: $form-rooming-items-header-background;

            h2 {
              color: $form-rooming-items-header-color;
            }
          }

          &-content {
            flex-grow: 1;

            .room {
              &-content {
                display: flex;
                flex-direction: column;
              }

              &-occupants {
                &-details {

                  > div:not(.room-occupants-label) {
                    margin-bottom: 10px;
                  }

                  .rooming-label {
                    font-weight: 700;
                    color: $black;
                  }

                  p, ul li {
                    text-align: center;
                    color: $black;
                  }
                }

                &-hotel, &-room-type {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  &-label {
                    margin-right: 4px;
                  }
                }

                &-participant-list {
                  margin-top: 5px;

                  ul li {
                    margin-top: 3px;

                    &:first-child {
                      margin-top: 0;
                    }
                  }
                }

                &-edit {
                  display: flex;
                  justify-content: center;
                  flex-grow: 1;
                  align-items: flex-end;
                  padding-top: 10px;

                  button {
                    background: $dark-blue;

                    &:hover {
                      background: $black;
                    }
                  }

                  > .form-item {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .field-errors-list {
      padding-top: 10px;
      text-align: center;
    }
  }

  .add-button {
    margin-top: 20px;

    &-text {

      span {
        display: block;
      }

      &-dont-forget {
        padding-top: 10px;
        font-size: $form-block-sub-title-headline-size;
        line-height: $form-block-sub-title-headline-line-height;
        font-weight: $form-block-sub-title-headline-weight;
        color: $form-block-sub-title-headline-color;
        font-style: italic;
      }

      &-add {
        display: block;
        margin-top: 30px;
      }
    }
  }


}


/*
.rooming-block {
  margin: 80px 0;

  .inscription-block-title {
    @media (max-width: $screen-xs-max) {
      padding: 0 20px 0 20px;
    }
  }

  .inscription-block-wrapper {
    padding: 0 20px 30px 20px !important;
    flex-flow: row wrap;
    align-items: flex-start;
    @media (max-width: $screen-xs-max) {
      padding: 0 20px 20px 20px !important;
    }

    .form-item.error {
      width: 100%;
    }
  }

  &-warning {
    width: 100%;
    margin: 40px auto 40px;
    @media (max-width: $screen-sm-max) {
      max-width: 100%;
      margin: 20px auto 40px;
    }

    p {
      font-family: $font-family-primary;
      max-width: 60%;
      margin: 20px auto 0;
      font-weight: bold;
      color: $blue;
      text-align: center;
      @media (max-width: $screen-sm-max) {
        max-width: 100%;
      }
    }
  }

  .error {
    ul li {
      text-align: center;
      padding-top: 10px !important;
    }
  }

  .add-registration-room {
    margin: 0 !important;
  }

  .overlay-modal {
    display: block;
  }

  .add-button-help {
    p {

      i {
        margin-top: 5px;
        padding-bottom: 10px;
        font-family: $font-family-primary;
        display: block;
        color: $blue;
        font-size: 12px;
        line-height: 14px;
        font-style: italic;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  &-item {
    width: 33.33%;
    padding: 0 20px;
    @media (max-width: $screen-md-max) {
      width: 50%;
    }
    @media (max-width: 875px) {
      width: 100%;
    }
    @media (max-width: $screen-xs-max) {
      padding: 0;
    }

    .close-block {
      display: none !important;
    }
  }

  .room-block {
    margin-bottom: 30px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }

    .room {
      &-header, &-content {
        width: 100%;
      }

      &-header {
        margin-top: 0;

        p {
          text-align: center;

          &.hotel-name {
            color: $blue;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 22px;
            line-height: 24px;
            padding-bottom: 20px;
            @media (max-width: $screen-xs-max) {
              font-size: 18px;
              line-height: 22px;
            }
          }

          &.room-type {
            padding-top: 10px;
            @media (max-width: $screen-xs-max) {
              padding-top: 0;
            }
          }
        }
      }

      &-content {
        display: flex;
        flex-flow: row wrap;
        margin-top: 30px;
        justify-content: center;
        @media (max-width: $screen-xs-max) {
          margin-top: 20px;
        }

        .room-occupants, .room-dates {
          .label {
            color: $blue;
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            padding: 0 0 10px;
            text-decoration: underline;
          }

          ul {
            li {
              font-family: $font-family-rubik;
              text-align: center;
              font-size: 14px;
              padding-bottom: 10px;
              font-weight: 400;
              color: $grey;
              line-height: 18px;

              &:last-child {
                padding-bottom: 0;
              }
            }
          }
        }

        .room-occupants {
          padding-right: 30px;
          width: 50%;
          @media (max-width: 1450px) {
            padding-right: 15px;
          }
          @media (max-width: $screen-xs-max) {
            padding-right: 0;
            width: 100%;
            margin-bottom: 20px;
          }
        }

        .room-dates {
          padding-left: 30px;
          width: 50%;
          @media (max-width: 1450px) {
            padding-left: 15px;
          }
          @media (max-width: $screen-xs-max) {
            padding-right: 0;
            width: 100%;
            padding-left: 0;
          }

          p {
            text-align: center;
            padding-bottom: 10px;
            @media (max-width: $screen-xs-max) {
              padding-bottom: 5px;
            }

            &:last-child {
              padding-bottom: 0;
            }
          }
        }

        .edit-container {
          margin-top: 30px;
          width: 100%;
          display: flex;
          justify-content: center;

          button {
            background: $blue;

            &:hover {
              background: $black;
            }
          }
        }
      }
    }
  }
}
*/
