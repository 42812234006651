//----- HERO ----//

//-- GLOBAL --//

//Background color
$hero-background-color: $blue;

// Hero title
$hero-title-color: $white;
$hero-title-font-size: 45px;
$hero-title-line-height: 56px;
$hero-title-font-size-lg: 36px;
$hero-title-line-height-lg: 44px;
$hero-title-font-size-xs: 24px;
$hero-title-line-height-xs: 30px;

// Hero sub title
$hero-subtitle-color: $white;
$hero-subtitle-font-size: 25px;
$hero-subtitle-line-height: 34px;
$hero-subtitle-font-size-xs: 18px;
$hero-subtitle-line-height-xs: 22px;


//-- IN LINE --//
$hero-in-line-max-width: 1502px;
$hero-in-line-padding-desktop: 70Px 0 70px;
$hero-in-line-padding-tablet: 80px 0 100px;
$hero-in-line-padding-mobile: 60px 0 70px;


//-- IN COLUMN --//
$hero-in-column-max-width: 1100px;
$hero-in-column-padding-desktop: 60px 0 100px;
