.inscription-activities {
  &-header {
    padding-bottom: 10px;

    p {
      &:last-child {
        padding-top: 20px;
      }

      span {
        display: block;
        color: $blue;
        font-size: 12px;
        font-style: italic;
      }
    }
  }

  &-recap {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;

    &-container {
      background: $dark-blue;
      padding: 20px 20px 0 20px;
      @media (max-width: $screen-xs-max) {
        padding: 20px 12px 0 12px;
      }

      .modal-block-container {
        /* width: 33.33%; */
        margin-top: 0;
        margin-bottom: 15px;
        @media (max-width: $screen-xs-max) {
          margin-bottom: 10px;
        }

        > p, > ul li {
          color: $white;
          font-family: $font-family-primary;
        }

        a {
          color: $white;
          @media (max-width: $screen-xs-max) {
            font-size: 12px;
          }

          &:hover {
            cursor: pointer;
            color: $white !important;
            text-decoration: underline;
          }
        }
        
        @media (max-width: $screen-md-max) {
          width: 50%;
        }
        @media (max-width: 819px) {
          width: 100% !important;
          padding: 0 !important;
        }
      }

      .inscription-activities-recap {
        &-header {
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid $white;

          p {
            color: $white;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px;
          }
        }

        &-content {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
        }

        &-text {
          display: flex;
          align-items: center;
          padding-bottom: 4px;

          b {
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            letter-spacing: 0;
            @media (max-width: $screen-xs-max) {
              font-size: 13px;
              line-height: 15px;
            }

          }

          button {
            font-size: 12px;
            background: unset;
            border: unset;
            height: unset;
            padding: 0 0 0 10px;
            text-transform: unset;
            font-style: italic;
            font-weight: 300;

            &:hover {
              text-decoration: underline;
              text-underline-offset: 2px;
            }
          }
        }
      }
    }
  }

  &-form {
    margin-top: 60px;

    .participantActivities {
      & > div {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -10px;

        .form-item {
          padding-left: 0 !important;
          width: 33.33%;
          @media (max-width: $screen-xs-max) {
            width: 100%;
          }

          > div {
            width: 100%;

            label {
              text-align: center;
              font-weight: bold;
              text-transform: uppercase;
              font-size: 16px;
              color: $black;
              padding-bottom: 15px !important;
              @media (max-width: $screen-xs-max) {
                padding-bottom: 10px !important;
                font-size: 15px;
              }
            }

            select {
              optgroup {
                margin-bottom: 10px;

              }
            }

            > .form-item {
              margin-bottom: 0 !important;
            }
          }
        }

        & > .form-item {
          padding: 0 10px !important;

          &:last-child {
            padding-bottom: 0;
          }

          .activityListActivity {
            label {
              @media (max-width: $screen-xs-max) {
                padding-right: 0;
                padding-bottom: 10px;
                display: block;
              }
            }

            select {
              width: 100%;
              @media (max-width: $screen-xs-max) {
                width: 100%;
              }
            }
          }

          & > div {
            & > .form-item {
              width: 100%;

              label {
                display: block;
                padding-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
}
