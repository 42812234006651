.registration-transport {
  .form-item-wantShuttle {
    margin-bottom: 0;

    > label {
      font-weight: 700;
      padding-bottom: 15px;
    }
  }

  .warning-message {
    margin-top: 35px;
  }
}
