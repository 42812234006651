.placement-block {
  margin: 80px 0 0;
  border-radius: $form-block-radius;

  .registration-block {
    &-header {
      border-top-right-radius: $form-block-radius;
      border-top-left-radius: $form-block-radius;
    }

    &-sub-title {
      &-headline {
        padding-top: 5px;
      }
    }

    &-content {
      display: flex;
      flex-flow: row wrap;
      position: relative;

      .registration-block-sub-title {
        width: 63%;
        @media (max-width: $screen-sm-max) {
          width: 55%;
        }
        @media (max-width: $screen-xs-max) {
          width: 100%;
          > p {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }

      .placement-block-buttons {
        width: 40%;
        @media (max-width: $screen-xs-max) {
          width: 100%;
          margin-top: 20px;
          align-items: center;
        }
      }
    }
  }

  &-buttons {
    position: absolute;
    right: 40px;
    top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    @media (max-width: $screen-xs-max) {
      position: relative;
      right: unset;
      top: unset;
      width: 100%;
    }

    button, a {
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2509803922);
      @media (max-width: $screen-xs-max) {
        width: 100%;
      }
    }

    .btn-refresh {
      background: $form-placement-btn-refresh-background !important;
      color: $form-placement-btn-refresh-color !important;

      &:hover {
        background: $form-placement-btn-refresh-background-hover !important;
        color: $form-placement-btn-refresh-color-hover !important;
      }
    }

    .btn-plan {
      background: $form-placement-btn-plan-background !important;
      color: $form-placement-btn-plan-color !important;
      margin-top: 15px;

      &:hover {
        background: $form-placement-btn-plan-background-hover !important;
        color: $form-placement-btn-plan-color-hover !important;
      }
    }

    .modal-popin {
      max-width: calc(#{$registration-wrapper-max-width} - 100px);
      @media (max-width: 1000Px) {
        max-width: calc(100vw - 100px);
      }
      @media (max-width: $screen-xs-max) {
        max-width: 100%;
      }
    }
  }

  &-zone {
    &-container {
      width: 100%;
      margin-top: 30px
    }

    &-item {
      margin-bottom: 10px;

      &-name {
        p {
          display: block;
          padding-bottom: 10px;
          font-weight: bold;

          text-decoration: underline;
          color: $form-placement-item-name-color !important;
        }
      }

      &-choices {
        > .form-item {
          margin-bottom: 0 !important;

          > div {
            .form-item {
              > label {
                font-weight: bold;
              }

              &.form-item-zone {
                margin-bottom: 10px;
                @media (max-width: $screen-xs-max) {
                  margin-bottom: 30px;
                }

                > label {
                  padding-bottom: 15px;
                }

                .form-item-choice-container {
                  display: flex;
                  flex-flow: row wrap;

                  .form-item-choice {
                    width: 40%;
                    @media (max-width: $screen-sm-max) {
                      width: 50%;
                    }
                    @media (max-width: $screen-xs-max) {
                      width: 100%;
                    }

                    &:nth-child(odd) {
                      padding-right: 20px;
                    }
                  }
                }
              }

              &.form-item-seat {
                select {
                  max-width: 300px;
                }
              }
            }
          }
        }
      }
    }
  }

  &-search {
    width: 100%;
    margin-top: 20px;

    .registration-block-sub-title {
      margin-bottom: 20px;
      width: 100% !important;
    }

    &-input {
      max-width: 70%;
      position: relative;
      @media (max-width: $screen-sm-max) {
        max-width: 100%;
      }

      button {
        position: absolute;
        right: 3px;
        top: 52%;
        transform: translateY(-50%);
        padding: 0;
        background: $white;
        border: none;
        cursor: pointer;
      }
    }

    &-results {
      margin-top: 20px;

      &-title {
        p {
          font-weight: bold;
          padding-bottom: 10px;
        }
      }

      &-list {
        table {
          border-collapse: collapse;
          max-width: 70%;
          width: 100%;
          @media (max-width: $screen-sm-max) {
            max-width: 100%;
          }
          @media (max-width: $screen-xs-max) {
            min-width: 100%;
          }

          tbody {
            tr {
              &.no-places {
                td:last-child {
                  background: $red;
                  color: $white;
                }
              }

              &:nth-child(even) {
                background-color: #f2f2f2;
              }
            }
          }


          th, td {
            padding: 8px;
            border: 1px solid $black;
            font-family: $form-components-font-family;
            font-size: 14px;
            color: $form-components-color;
            overflow-wrap: anywhere;

            @media (max-width: $screen-xs-max) {
              padding: 8px 4px;
              width: 33.33%;
              font-size: 12px;
            }

            &:first-child {
              width: 30%;
            }


            &.empty {
              font-weight: 600;
            }
          }

          td {
            overflow-wrap: anywhere;
          }

          thead {
            background: $form-placement-table-thead-background;

            th {
              text-align: left;
              color: $form-placement-table-thead-color;
              @media (max-width: $screen-xs-max) {
                text-align: center;
              }

              &:nth-child(2) {
                min-width: 100px;
                @media (max-width: $screen-xs-max) {
                  min-width: 80px;
                }
              }

            }
          }


        }
      }
    }
  }
}
