.my-inscriptions-page {
  height: 100vh;

  .layout-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    main {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .layout-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.my-inscriptions {
  &-title {
    .inscription-title {
      background: $blue;
      color: $white;

      h1 {
        max-width: 1000px;
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        font-size: 32px;
        line-height: 30px;
      }
    }
  }

  &-content {
    padding: 100px 0;
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;

    p.no-registration {
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      width: 100%;
    }

    table {
      font-family: $font-family-primary;

      tr {
        padding: 20px 0;
        display: flex;
        position: relative;

        &:before {
          border-top: 2px dotted #234992;
          content: "";
          display: block;
          height: 2Px;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        &:first-child {
          padding: 0 0 20px 0;

          &:before {
            display: none;
          }
        }

        td {
          padding-right: 30px;
          display: flex;
          align-items: center;

          a {
            color: $blue;

            &:hover {
              color: $black;
            }
          }

          &:first-child {
            flex-grow: 1;
            font-weight: bold;
            font-size: 18px;
            text-transform: uppercase;
          }

          &:last-child {
            padding-right: 0;

          }
        }
      }
    }
  }

}
