#activites {
  .container-wrapper {
    padding-right: 40px;
    padding-left: 40px;
    @media only screen and (max-width: $screen-xs-max) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

.activity {
  &-title {
    background: $activity-bandeau-background-color;
    color: $activity-bandeau-text-color;
  }

  &-intro {
    margin-bottom: 50px;

    p {
      text-align: center;
      margin: 0 auto;
      font-size: 18px;
      line-height: 26px;
      color: $activity-intro-text-color;
      @media (max-width: $screen-xs-max) {
        padding: 0 15px;
        br {
          display: none;
        }
      }
    }
  }

  &-content {
    max-width: 1294px;
    margin: 0 auto;
    padding: 50px 0 80px;
    @media (max-width: $screen-xs-max) {
      padding: 40px 0;
    }

    &.in-line {
      .activity-list {
        display: flex;
        flex-flow: row wrap;
        @media (max-width: $screen-md-max) {
          justify-content: center;
        }

        &-container {
          width: 33.33%;
          @media (max-width: $screen-md-max) {
            width: 50%;
            &:last-child {
              margin-top: 60px;
            }
          }
          @media (max-width: $screen-xs-max) {
            width: 100%;
            margin-top: 50px;
            &:first-child {
              margin-top: 0;
            }
          }

        }

        &-item {
          padding: 0 10px;
        }
      }
    }

    &.in-mosaic {
      .activity-list {
        max-width: 1284px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        @media (max-width: $screen-sm-max) {
          flex-flow: row wrap;
        }
        @media (max-width: $screen-xs-max) {
          flex-direction: column;
        }

        &-container {
          display: flex;
          width: 33.33%;
          @media (max-width: $screen-sm-max) {
            width: 50%;

            &:last-child {
              margin-top: 60px;
            }
          }
          @media (max-width: $screen-xs-max) {
            flex-direction: column;
            width: 100%;
            &:last-child {
              margin-top: 10px;
            }
          }

          &.abba {
            .modal-block-img img {
              transform: rotate(180deg);
            }
          }
        }

        &-col {
          width: 33.33%;
          display: flex;
          flex-direction: column;
          padding: 0 10px;
          @media (max-width: $screen-xs-max) {
            width: 100%;
            padding: 0;
            &.activity-list {
              &-col-1 {
                order: 1;
              }

              &-col-2 {
                order: 3;
              }

              &-col-3 {
                order: 2;
              }
            }

          }

          .activity-list-item {
            margin-bottom: 20px;

            &:last-child {
              margin-bottom: 0;
              @media (max-width: $screen-xs-max) {
                margin-bottom: 20px;
              }
            }
          }
        }

        &-item {
          .modal-block {
            &-content {
              padding: 22px 30px 0;
              position: absolute;
              bottom: 0;
              max-height: 210px;
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-end;
              background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 5%, rgba(106, 106, 106, 0.4) 60%, transparent);
              @media (max-width: $screen-sm-max) {
                padding: 22px 15px 0;
              }

              &.blue {
                background: $secondary-color;

                button {
                  color: $secondary-color !important;
                }
              }

              &.brown {
                background: $third-color;

                button {
                  color: $third-color !important;
                }
              }

              h3 {
                color: $white;
                margin-bottom: 45px;
                text-align: center;
                line-height: 28px;
                @media (max-width: $screen-sm-max) {
                  font-size: 17px;
                  line-height: 21px;
                }
                @media (max-width: $screen-xs-max) {
                  font-size: 20px;
                  line-height: 28px;
                }
              }

              button {
                width: 56px;
                min-width: 56px;
                max-width: 56px;
                height: 56px;
                min-height: 56px;
                max-height: 56px;
                border: none;
                background: #5D717A;
                text-transform: unset;
                font-weight: 500;
                letter-spacing: 0.5px;
                box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
                position: absolute;
                bottom: -20px;
                font-size: 39px;
                padding: 0 0 5px 0;

                &:hover {
                  background: $black;
                  color: $white !important;
                }
              }
            }

            &-container {
              margin: 0 10px;
              position: relative;
              @media (max-width: $screen-xs-max) {
                margin: 0 0 50px;

              }
            }

            &-img {
              display: flex;
            }
          }
        }
      }
    }
  }

}
