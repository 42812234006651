.maintenance-page {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .layout-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          color: $blue;
          padding-bottom: 30px;
        }

        p {
          text-align: center;
        }
      }
    }
  }
}
