.hotel {
  &-list {
    .modal {
      &-block-title {
        h3 {
          font-family: $hebergement-hotel-name-font-family;
          color: $hebergement-hotel-name-color;
          background: $hebergement-hotel-name-background;
          padding-bottom: 10px;
          text-transform: uppercase;
        }
      }

      &-popin-sub-title {
        p {
          .westin {
            display: block;
            font-style: italic;
          }
        }
      }
    }

    &.multiple {
      display: flex;
      flex-flow: row wrap;
      @media (max-width: $screen-md-max) {
        justify-content: center;
      }

      .hotel-list-container {
        width: 25%;
        @media (max-width: $screen-md-max) {
          width: 50%;
          &:nth-child(3), &:last-child {
            margin-top: 60px;
          }
        }
        @media (max-width: $screen-xs-max) {
          width: 100%;
          margin-top: 50px;
          &:first-child {
            margin-top: 0;
          }
        }
      }

      .hotel-list-item {
        padding: 0 15px;

      }

      .modal-container {
        &.hotel-multiple {

          .modal-popin-text .field__item {
            p {
              font-size: 12px;

              &:last-child {
                width: auto;
                padding-left: 150px;
              }

              @media (max-width: $screen-sm-max) {
                &:first-child {
                  width: 70%;
                }
                &:last-child {
                  width: auto;
                  padding-left: 100px;
                }
              }
              @media (max-width: $screen-xs-max) {
                &:first-child {
                  width: 100%;
                }
                &:last-child {
                  width: 100%;
                  padding-left: 0;
                  padding-top: 30px !important;
                }
              }
            }
          }
        }
      }
    }

    &.single {
      .modal {
        &-container.hotel-single {
          margin-top: 0;

          .modal-popin-text {
            .modal-text {
              .modal-text-content ul li {
                margin-bottom: 10px;
              }
            }
          }
        }

        &-block {
          position: relative;

          &-img {
            width: 100%;

            .slick-list {
              padding: 0 25%;

              @media (max-width: $screen-sm-max) {
                padding: 0 15%;
              }
              @media (max-width: $screen-xs-max) {
                padding: 0 40px;
              }

              .slick-slide {
              }
            }

            img {
              width: 100%;
            }
          }

          &-title {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 257px;
            min-width: 257px;
            max-width: 257px;
            height: 257px;
            min-height: 257px;
            max-height: 257px;
            background: $hebergement-hotel-name-background;
            padding: 20px 10px;
            border-radius: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            @media (max-width: $screen-xs-max) {
              padding: 20px 15px 10px 15px;
              position: relative;
              bottom: 0;
              max-width: 100%;
              top: unset;
              transform: unset;
              height: auto;
              min-height: auto;
              max-height: auto;
            }

            h3 {
              background: unset;
              text-align: center;
              color: $black;
            }

            button {
              margin: 0 auto;
              background: $dark-blue;
              border: 3px solid $white;
              font-family: brandon-grotesque, sans-serif;
              width: 48px;
              min-width: 48px;
              max-width: 48px;
              height: 48px;
              min-height: 48px;
              max-height: 48px;
              font-size: 39px;
              padding-bottom: 5px;

              &:hover {
                background: $gold;
                border: 3px solid $black;
                color: $black !important;
              }
            }
          }

          &-links {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 52px 20px 45px;
            @media (max-width: $screen-sm-max) {
              flex-flow: row wrap;
            }
            @media (max-width: $screen-xs-max) {
              padding: 0 20px 70px;
              flex-direction: column;
              flex-wrap: unset;
            }

            &-items {
              display: flex;
              align-items: center;
              margin-right: 50px;
              @media (max-width: $screen-xs-max) {
                margin-top: 10px;
                margin-right: 0;
              }

              &:last-child {
                padding-right: 0;
              }

              a {
                margin-right: 10px;
                font-size: 14Px;
                line-height: 20px;
                font-family: $font-family-brandon;
                font-weight: $font-weight-bold;
                color: $dark-blue;
                text-transform: uppercase;
                text-decoration: underline;

              }

              &.map {
                a {
                  margin-right: 3px;
                }
              }

              &.tripadvisor {
                @media (max-width: 901px) {
                  margin-top: 20px;
                }
                @media (max-width: $screen-xs-max) {
                  margin-top: 10px;
                }
              }


              &:hover {
                a {
                  color: $black;
                }

                svg {
                  path {
                    stroke: $black;

                    &#Icon_simple-openstreetmap {
                      fill: $black;
                    }

                    &#Icon_awesome-tripadvisor {
                      stroke: unset;
                      fill: $black;
                    }

                  }

                  circle {
                    fill: $black;
                  }
                }

              }

            }
          }

        }
      }
    }
  }
}
