//PROGRAMME
.u-margin-bottom-15 {
  margin-bottom: 15px !important;
}

.u-margin-bottom-25 {
  margin-bottom: 25px !important;
}

.u-margin-top-10 {
  margin-top: 10px !important;
}

.u-margin-top-15 {
  margin-top: 15px !important;
}

.u-margin-top-25 {
  margin-top: 25px !important;
}

.u-padding-top-10 {
  padding-top: 10px !important;
}

.u-margin-bottom-mobile-60 {
  @media (max-width: $screen-xs-max) {
    margin-bottom: 60px !important;
  }
}

// FONT-WEIGHT
.u-font-normal {
  font-weight: normal !important;
}

.u-font-weight-500 {
  font-weight: 500;
}

.u-font-weight-300 {
  font-weight: 300;
}

.u-font-bold {
  font-weight: 600 !important;
}

// TEXT-ALIGN
.u-txt-align-center {
  text-align: center !important;
  text-align-last: center !important;
}

.u-txt-align-left {
  text-align: left !important;
  text-align-last: left !important;
}

.u-txt-align-right {
  text-align: right;
}

// TEXT-TRANSFORM
.u-txt-uppercase {
  text-transform: uppercase;
}

.u-txt-capitalize {
  text-transform: capitalize;
}

// TEXT-DECORATION
.u-txt-deco--none {
  text-decoration: none;
}

.u-txt-deco--none-hover {
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
}

.u-txt-deco--underline {
  text-decoration: underline;
}

// FLOAT
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

// POSITION
.u-pos-relative {
  position: relative;
}

.u-pos-absolute {
  position: absolute !important;
}

.u-pos-initial {
  position: initial !important;
}

.u-pos-fixed {
  position: fixed !important;
}


// DISPLAY
.u-d-block {
  display: block;
}

.u-d-flex {
  display: flex !important;
}

.u-d-none {
  display: none !important;
}

.u-d-inline {
  display: inline !important;
}

// BOTTOM
.u-bottom-0 {
  bottom: 0;
}

// FLEX
.u-flex-column {
  flex-direction: column;
}

.u-flex-grow-1 {
  flex-grow: 1;
}

.u-flex-row-wrap {
  flex-flow: row wrap;
}

.u-flex-row-reverse {
  flex-direction: row-reverse;
}

.u-justify-center {
  justify-content: center;
}

.u-justify-flex-end {
  justify-content: flex-end;
}

.u-justify-between {
  justify-content: space-between;
}

.u-align-self-center {
  align-self: center;
}

.u-align-center {
  align-items: center;
}

.u-align-start {
  align-items: flex-start;
}

// LIST-STYLE
.u-list-style--none {
  list-style: none;
}

// VERTICAL ALIGN
.u-vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.u-vertical-align-bottom {
  vertical-align: bottom;
}

.u-vertical-align-top {
  vertical-align: top;
}

// WIDTH
.u-width-100 {
  width: 100% !important;
}

.u-width-auto {
  width: auto !important;
}

// HEIGHT:
.u-height-100 {
  height: 100%;
}

// MIN-HEIGHT

.u-min-height-98 {
  min-height: 98px;
}

.u-min-height-140 {
  min-height: 140px;
}

.u-min-height-500 {
  min-height: 500px;
}

.u-max-width-200 {
  max-width: 200px;
}

// CURSOR
.u-cursor-pointer {
  cursor: pointer;
}

// BORDER
.border-none {
  border: none !important;
}

.border-top-none {
  border-top: none !important;
}


//TABLE
.u-table-no-border {
  td, th {
    border-top: none !important;
    border-bottom: none !important;
  }
}

//RIGHT
.u-after-right-20 {
  &:after {
    right: 20px !important;
    top: 31px !important;
  }
}

.u-before-right-16 {
  &:before {
    right: 16px !important;
    top: 31px !important;
  }
}

// TOP:
.u-top-0 {
  top: 0 !important;
}

// LETTER-SPACING:
.u-letter-spacing-05 {
  letter-spacing: 0.5px;
}

.u-outline {
  outline: -webkit-focus-ring-color auto 5px;
}

.u-outline-none {
  outline: none;
}

// Z-INDEX
.u-index-1000 {
  z-index: 1000;
}


// hidden but present
.hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}

// hidden but present
.u-overflow-hidden {
  overflow: hidden !important;
}

// PADDING

.u-padding-bottom-15 {
  padding-bottom: 15px !important;
}

.u-padding-bottom-17 {
  padding-bottom: 17px !important;
}

.u-margin-bottom-10 {
  margin-bottom: 10px !important;
}

.u-padding-bottom-56 {
  padding-bottom: 56px !important;
}

.u-padding-bottom-65 {
  padding-bottom: 65px !important;
}

.u-padding-bottom-26 {
  padding-bottom: 26px !important;
}

.u-padding-bottom-31 {
  padding-bottom: 31px !important;
}

.u-padding-bottom-32 {
  padding-bottom: 32px !important;
}

.u-padding-bottom-80 {
  padding-bottom: 80px !important;
}

.no-padding {
  padding: 0;
}

// MARGIN
.no-margin {
  margin: 0;
}

.blue {
  color: $black !important;
}


// SIZE
.size-100 {
  width: 100%;
}

.size-70 {
  width: 70%;
}

.size-66 {
  width: 66.66%;
}

.size-65 {
  width: 65%;
}

.size-60 {
  width: 60%;
  @media (max-width: $screen-sm-max) {
    width: 50%;
  }
}

.size-57 {
  width: 57%;
}

.size-55 {
  width: 55%;
}

.size-53 {
  width: 53%;
}

.size-50 {
  width: 50%;
}

.size-47 {
  width: 47%;
}

.size-45 {
  width: 45%;
}

.size-43 {
  width: 43%;
}

.size-40 {
  width: 40%;
  @media (max-width: $screen-sm-max) {
    width: 50%;
  }
}

.size-35 {
  width: 35%;
}

.size-33 {
  width: 33.33%;
}

.size-30 {
  width: 30%;
}
