.map {

  &-title {
    background: $plan-de-vol-background;

    h2 {
      color: $plan-de-vol-color;
    }

  }

  .container-wrapper {
    padding-right: 0 !important;
    padding-left: 0 !important;

    @media (max-width: $screen-xs-max) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .image-desktop, .image-mobile {
    img {
      width: 100%;
      height: auto;
      display: flex;
    }
  }

  .image-desktop {
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  .image-mobile {
    display: none;
    @media (max-width: $screen-xs-max) {
      display: block;
    }
  }

}
