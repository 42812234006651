//BRICOLAGE
//.tarifs {
//
//  &-content {
//    padding: 80px 0 50px;
//  }
//  // TODO A VOIR
//  &.registration {
//    .tarifs-content {
//      padding-bottom: 200px;
//      @media (max-width: $screen-sm-max) {
//        padding-bottom: 100px;
//      }
//      @media (max-width: $screen-xs-max) {
//        padding-bottom: 150px;
//      }
//    }
//
//    // VAGUE MR BRICO
//    .ellipse-block {
//      width: 110%;
//      left: -5%;
//      display: flex;
//      background: $black;
//      height: 290px;
//      clip-path: ellipse(50% 50%);
//      position: absolute;
//      top: -90px;
//      @media (max-width: 1700px) {
//        width: 130%;
//        left: -15%;
//      }
//      @media (max-width: 1100px) {
//        height: 200px;
//      }
//      @media (max-width: $screen-sm-max) {
//        width: 120%;
//        left: -10%;
//      }
//      @media (max-width: $screen-xs-max) {
//        top: -65px;
//        height: 100px;
//      }
//
//      &-bottom {
//        width: 110%;
//        left: -5%;
//        display: flex;
//        background: $black;
//        height: 300px;
//        clip-path: ellipse(50% 50%);
//        position: absolute;
//        bottom: -90px;
//        justify-content: center;
//        align-items: center;
//        @media (max-width: 1700px) {
//          width: 130%;
//          left: -15%;
//        }
//        @media (max-width: 1100px) {
//          height: 200px;
//        }
//        @media (max-width: $screen-sm-max) {
//          width: 120%;
//          left: -10%;
//        }
//        @media (max-width: $screen-xs-max) {
//          bottom: -65px;
//          height: 200px;
//          width: 160%;
//          left: -30%;
//        }
//
//        svg {
//          margin-bottom: 50px;
//          @media (max-width: $screen-xs-max) {
//            width: 140px;
//            height: auto;
//          }
//        }
//      }
//    }
//
//    .tarifs-card.room-more {
//      @media (max-width: $screen-xs-max) {
//        margin-bottom: 0;
//      }
//
//    }
//  }
//
//  .wave-container {
//    display: flex;
//    width: 100%;
//    height: auto;
//    margin-top: -2px;
//
//    svg {
//      width: 100%;
//      height: auto;
//    }
//  }
//
//  h2 {
//    padding-top: 100px;
//    margin-bottom: 0;
//    text-transform: uppercase;
//    color: $black;
//    @media (max-width: $screen-sm-max) {
//      padding-top: 40px;
//    }
//    @media (max-width: $screen-xs-max) {
//      padding-top: 0;
//    }
//  }
//
//  &-container {
//    max-width: 1490px;
//    margin: 0 auto;
//    padding: 40px 50px 0;
//    @media (max-width: 1370px) {
//      max-width: 1464px;
//      padding: 0 24px;
//    }
//    @media (max-width: 1210px) {
//      max-width: 1444px;
//    }
//    @media (max-width: $screen-sm-max) {
//      padding: 50px 40px 0;
//    }
//    @media (max-width: $screen-xs-max) {
//      padding: 40px 20px 0;
//    }
//
//    &.registration {
//      padding: 100px 50px 0;
//      @media (max-width: $screen-sm-max) {
//        padding: 80px 40px 0;
//      }
//      @media (max-width: $screen-xs-max) {
//        padding: 70px 20px 0;
//      }
//    }
//  }
//
//  &-content {
//    background: $light-grey;
//
//    .subtitle {
//      padding-bottom: 100px;
//      text-align: center;
//      font-size: 22px;
//      line-height: 30Px;
//      @media (max-width: $screen-xs-max) {
//        font-size: 18px;
//        line-height: 26Px;
//        padding-bottom: 60px;
//        br {
//          display: none;
//        }
//      }
//    }
//  }
//
//  &-cards {
//    display: flex;
//    flex-flow: row wrap;
//    justify-content: center;
//
//    .tarifs-card {
//      width: 33.33%;
//      padding: 0 43px;
//      @media (max-width: 1370px) {
//        padding: 0 30px;
//      }
//      @media (max-width: 1210px) {
//        padding: 0 20px;
//      }
//      @media (max-width: $screen-sm-max) {
//        padding: 0 10px;
//        width: 50%;
//      }
//      @media (max-width: $screen-xs-max) {
//        width: 100%;
//        padding: 0 0 20px;
//      }
//
//      &-container {
//        background: $white;
//        padding: 50px 30px 27px;
//        border-radius: 18px;
//        display: flex;
//        flex-direction: column;
//        align-items: center;
//        height: 100%;
//        @media (max-width: $screen-sm-max) {
//          padding: 30px 15px 20px;
//        }
//      }
//
//      &-header {
//        padding-bottom: 43px;
//        @media (max-width: $screen-xs-max) {
//          padding-bottom: 30px;
//        }
//
//        .tarfis-card-picto {
//          height: 135px;
//          display: flex;
//          align-items: flex-end;
//          @media (max-width: $screen-sm-max) {
//            svg {
//              width: 100%;
//            }
//          }
//        }
//      }
//
//      &-content {
//        .tarifs-card {
//          &-title {
//            h3 {
//              font-family: $font-family-primary;
//              font-size: 22px;
//              line-height: 26px;
//              font-weight: bold;
//              text-align: center;
//              @media (max-width: 1446px) {
//                font-size: 20px;
//                line-height: 24px;
//              }
//              @media (max-width: 1290px) {
//                font-size: 18px;
//                line-height: 23px;
//              }
//
//            }
//          }
//
//          &-subtitle {
//            padding-bottom: 30px;
//            @media (max-width: $screen-xs-max) {
//              padding-bottom: 20px;
//            }
//
//
//            p {
//              font-size: 16px;
//              line-height: 19Px;
//              text-align: center;
//              @media (max-width: 1290px) {
//                font-size: 14px;
//                line-height: 17px;
//              }
//            }
//          }
//
//          &-price {
//            padding-bottom: 40px;
//            @media (max-width: $screen-xs-max) {
//              padding-bottom: 30px;
//            }
//
//            p {
//              text-align: center;
//              font-size: 42px;
//              line-height: 48px;
//              font-weight: bold;
//              color: $black;
//              @media (max-width: $screen-xs-max) {
//                font-size: 36px;
//                line-height: 42px;
//              }
//            }
//          }
//
//          &-text {
//            padding-bottom: 20px;
//
//            p {
//              font-size: 18px;
//              line-height: 21px;
//              text-align: center;
//              color: $dark;
//              @media (max-width: 1446px) {
//                font-size: 16px;
//                line-height: 22px;
//              }
//            }
//          }
//        }
//      }
//
//      &-footer {
//        p {
//          font-size: 14px;
//          line-height: 16px;
//          font-style: italic;
//          text-align: center;
//          color: rgba(30, 70, 64, .53);
//        }
//      }
//
//      &.free, &.room-more {
//        .tarifs-card-container {
//          background: $dark;
//
//          h3, p {
//            color: $white;
//          }
//
//          .tarifs-card-footer {
//            p {
//              color: rgba(255, 255, 255, .53);
//            }
//          }
//        }
//      }
//
//      &.free, &.one-more, &.one-accompagnant {
//        .tarifs-card-content {
//          display: flex;
//          flex-direction: column;
//          flex-grow: 1;
//
//          .tarifs-card-price {
//            flex-grow: 1;
//            display: flex;
//            justify-content: center;
//            align-items: flex-end;
//          }
//        }
//      }
//
//      &.one-more, &.one-accompagnant {
//        .tarifs-card-title {
//          padding-bottom: 47px;
//          @media (max-width: $screen-xs-max) {
//            padding-bottom: 35px;
//          }
//        }
//
//      }
//
//      &.free {
//        .tarifs-card-title {
//          padding-bottom: 5px;
//        }
//      }
//
//      &.room-more {
//        width: auto;
//        display: flex;
//        margin-top: 110px;
//        margin-bottom: 60px;
//        @media (max-width: $screen-sm-max) {
//          margin-top: 60px;
//        }
//        @media (max-width: $screen-xs-max) {
//          margin-top: 0;
//        }
//
//        .tarifs-card-container {
//          padding: 35px 50px 35px 40px;
//          flex-direction: row;
//          @media (max-width: $screen-xs-max) {
//            flex-direction: column;
//          }
//
//          .tarifs-card {
//
//            &-header {
//              padding-bottom: 0;
//              padding-right: 23px;
//              @media (max-width: $screen-xs-max) {
//                padding-right: 0;
//                padding-bottom: 30px;
//              }
//            }
//
//            &-content {
//              padding-right: 30px;
//              @media (max-width: $screen-xs-max) {
//                padding-right: 0;
//                padding-bottom: 30px;
//              }
//            }
//
//            &-picto {
//              height: auto;
//            }
//
//            &-title {
//              padding-bottom: 15px;
//
//              h3 {
//                text-align: left;
//                font-size: 20px;
//                line-height: 25px;
//                @media (max-width: $screen-xs-max) {
//                  text-align: center;
//                  font-size: 18px;
//                  line-height: 23px;
//                }
//              }
//            }
//
//            &-text {
//              padding-bottom: 0;
//
//              p {
//                font-size: 15px;
//                line-height: 17px;
//                text-align: left;
//                @media (max-width: $screen-xs-max) {
//                  text-align: center;
//                  br {
//                    display: none;
//                  }
//                }
//              }
//            }
//
//            &-price {
//              p {
//                font-size: 42px;
//                line-height: 48px;
//                font-weight: bold;
//                @media (max-width: $screen-xs-max) {
//                  font-size: 36px;
//                  line-height: 42px;
//                }
//              }
//            }
//          }
//        }
//      }
//    }
//  }
//}

//PLAZA
.tarifs {
  &-wrapper {
    position: relative;
    padding: 80px 0 !important;
    @media only screen and (max-width: $screen-xs-max) {
      padding: 50px 0 !important;
    }

  }

  &-content {
    max-width: 987px !important;
    margin: 0 auto;
    padding-right: 50px !important;
    padding-left: 50px !important;
    @media (max-width: $screen-sm-max) {
      max-width: 700px !important;
    }
    @media only screen and (max-width: $screen-xs-max) {
      padding-right: 20px !important;
      padding-left: 20px !important;
    }



    &.in-line {
      .tarifs-list {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        flex-grow: 1;
        @media (max-width: $screen-xs-max) {
          padding: 0;
        }

        &-container {
          width: 40%;
          margin-top: 0;

          @media (max-width: $screen-sm-max) {
            width: 50%;
            &:nth-child(3) {
              margin-top: 60px;
            }
          }
          @media (max-width: $screen-xs-max) {
            width: 100%;
            margin-top: 40px !important;
          }
        }

        &-item {
          box-shadow: 0 2px 7px rgb(0, 0, 0, .25);
          margin: 0 8px;
          height: 100%;
          display: flex;
          flex-direction: column;
          background: #F3F3F7;
          padding: 50px 30px 40px;
          @media (max-width: $screen-xs-max) {
            margin: 0;
            padding: 50px 20px 40px;
          }

          .tarifs {

            &-text-container {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
            }

            &-title, &-sub-title, &-text {
              p {
                color: $white;
                text-align: left;
              }
            }

            &-name {
              display: flex;
              flex-direction: column;

              h3 {
                text-transform: uppercase;
                text-align: center;
                position: relative;
                padding-bottom: 23px;
                margin-bottom: 80px;
                @media (max-width: $screen-xs-max) {
                  margin-bottom: 50px;
                  font-size: 16px;
                  line-height: 20px;
                }

                &:before {
                  content: "";
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  bottom: 0;
                  background: $black;
                  border-radius: 10px;
                  width: 80px;
                  height: 4px;
                }
              }
            }

            &-list {
              ul {
                width: 100%;
                padding-bottom: 100px;
                @media (max-width: $screen-xs-max) {
                  padding-bottom: 70px;
                }

                li {
                  padding: 7px 0;
                  display: flex;
                  align-items: center;
                  flex-flow: row wrap;
                  position: relative;

                  &.no-padding {
                    padding: 0;
                  }

                  &.red {
                    span {
                      color: $black;
                      font-weight: bold;
                    }
                  }

                  svg {
                    width: 22px !important;
                    height: 22px !important;
                    position: absolute;
                    top: 6px;
                    left: 0;
                  }

                  .nota-bene {
                    display: block;
                    padding-top: 5px;
                    font-style: italic;
                    font-size: 14px;
                    line-height: 17px;
                  }


                  span {
                    padding-left: 20px;
                    margin-left: 15px;
                    font-family: $font-family-primary;
                    font-size: 16px;
                    line-height: 19px;
                  }
                }
              }
            }

            &-price {
              padding: 15px 20px;
              background: $black;
              @media (max-width: $screen-xs-max) {
                padding: 10px 20px;
              }

              p {
                color: $white;
                text-align: center;
                font-size: 22px;
                line-height: 28px;
                font-weight: bold;
                @media (max-width: $screen-xs-max) {
                  font-size: 20px;
                  line-height: 26px;
                }

              }
            }

          }
        }
      }
    }

  }

}
