.slick-slider {
  .slick-list {
    margin: 0;

    .slick-slide {
      img {
        display: block;
        width: 100%;
        height: auto;

      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    right: 30px;
    cursor: pointer;
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
    z-index: 4;
    border: none;
    transform: translateY(-50%);

    &:before, &:after {
      border-right: 2px solid;
      content: '';
      display: block;
      height: 20px;
      margin-top: -14px;
      position: absolute;
      -moz-transform: rotate(125deg);
      -o-transform: rotate(125deg);
      -webkit-transform: rotate(125deg);
      transform: rotate(125deg);
      right: 17px;
      top: 50%;
      width: 0;
    }

    &:after {
      margin-top: -3px;
      -moz-transform: rotate(55deg);
      -o-transform: rotate(55deg);
      -webkit-transform: rotate(55deg);
      transform: rotate(55deg);
    }

    &.slick-prev {
      right: unset;
      left: 30px;

      &:before {
        -moz-transform: rotate(55deg);
        -o-transform: rotate(55deg);
        -webkit-transform: rotate(55deg);
        transform: rotate(55deg);
      }

      &:after {
        -moz-transform: rotate(125deg);
        -o-transform: rotate(125deg);
        -webkit-transform: rotate(125deg);
        transform: rotate(125deg);
      }
    }

    &.arrow-white {
      &:before, &:after {
        border-right: 2px solid #fff;
      }
    }
  }

  &.slick-hero {
    .slick-arrow {
      top: 400px;
      transform: unset;
      @media only screen and (max-width: $screen-md-max) {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.slick-dots {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 40px 0;
  padding: 0;

  li {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    button {
      background: none;
      border: 2px solid $gold;
      text-indent: -1000px;
      overflow: hidden;
      width: 18px !important;
      height: 18px !important;
      min-width: 18px !important;
      min-height: 18px !important;
      max-width: 18px !important;
      max-height: 18px !important;
      border-radius: 100%;
      cursor: pointer;
    }

    &.slick-active {
      button {
        background: $gold;
      }
    }
  }
}
