.room-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  z-index: 502;
  max-height: 95vh;
  width: 90vw;
  height: auto;
  border-radius: 10px;
  overflow: auto;
  max-width: calc(#{$registration-wrapper-max-width} - 100Px);
  padding: 35px 48px;

  @media (max-width: $screen-xs-max) {
    padding: 0;
    border-radius: 0;
    overflow: scroll;
    width: 100%;
    height: 100%;
    max-height: unset;
    display: flex;
    flex-direction: column;
  }

  &-header {
    padding-bottom: 10px;
    border-bottom: 1px solid $modal-header-border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $screen-xs-max) {
      margin: 20px 20px 0;
    }
  }

  &-fixed-title {
    align-self: flex-end;

    h4 {
      color: $modal-header-text-color;
      letter-spacing: 0.5px;
    }
  }

  &-close {
    position: relative;
    right: unset;
    top: unset;
    width: 60px;
    height: 60px;
    z-index: 505;
    cursor: pointer;
    background: none;
    border: none;
    margin-top: -41px;
    margin-right: -19px;

    svg {
      width: 100%;
      height: auto;
      margin-top: 26px;

    }
  }

  &-content {
    padding-top: 20px;
    @media (max-width: $screen-xs-max) {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .room-submit-container {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
      }
    }

    .room-modal-premium {
      @media (max-width: $screen-xs-max) {
        margin: 0 20px 0;
      }


      > label {
        margin-bottom: 14px;
        display: block;
        width: 100%;
        font-weight: bold;
      }

      .form-item-choice-container {
        @media (max-width: $screen-xs-max) {
          display: flex;
          flex-direction: column;
        }

        .form-item-choice {
          &:last-child {
            margin-left: 25px;
            @media (max-width: $screen-xs-max) {
              margin-left: 0;
              margin-top: 15px !important;
            }
          }

          label {
            @media (max-width: $screen-xs-max) {
              line-height: 18px;
              &:before {
                top: 0;
              }
              &:after {
                top: 3px;
              }
            }


          }
        }
      }

      &-message {
        padding-top: 20px;

        .text-upp {
          text-transform: uppercase;
        }

        p {
          color: $black;
          padding-bottom: 10px;

          &:last-child {
            padding-bottom: 0;
          }

          a {
            font-weight: bold;
            color: $black;

            &:hover {
              color: $dark-blue !important;
            }
          }
        }
      }
    }

    .error-message {
      margin-top: 20px;
      text-align: center;
      color: red;
    }

    .help {
      margin-bottom: 30px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: $dark-blue;
      @media (max-width: $screen-sm-max) {
        br {
          display: none;
        }
      }
      @media (max-width: $screen-xs-max) {
        margin: 0 20px 30px;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .select-label {
      @media (max-width: $screen-xs-max) {
        margin: 0 20px;
      }

      label {
        font-weight: bold;
      }
    }

    .room-modal-hotels {
      padding-top: 10px;

      label {
        font-weight: bold;
      }
    }

    .room-form {
      display: flex;
      flex-flow: row wrap;
      margin-top: 35px;
      @media (max-width: $screen-xs-max) {
        padding: 0 20px;
      }

      &.room-supplement {
        margin-top: unset;
      }

      .form-item {
        &.typeOfRoom {
          display: flex;
          flex-flow: row wrap;
          margin-bottom: 20px;
          width: 70%;
          @media (max-width: $screen-sm-max) {
            width: 100%;
          }

          > label {
            margin-bottom: 14px;
            display: block;
            width: 100%;
            font-weight: bold;
          }

          > div {
            padding-right: 40px !important;
            width: 100%;
            margin-bottom: 10px;
            @media (max-width: $screen-sm-max) {
              padding-right: 0 !important;
            }
            @media (max-width: $screen-xs-max) {
              width: 100%;
              margin-right: 0 !important;
              margin-bottom: 15px !important;
            }

            &:last-child {
              margin-right: 0 !important;
            }
          }
        }

        label {
          display: block;
          margin-bottom: 5px;
          line-height: 16px;

          &:before {
            top: 0px;
          }

          &:after {
            top: 3px;
          }

          > span {
            margin-top: 3Px;
            /* display: block; */
          }
        }

        &.arrivalDateBefore, &.DepartureDateAfter {
          width: 50%;
          display: flex;
          flex-direction: column;
          @media (max-width: $screen-xs-max) {
            width: 100%;
            padding: 0 !important;
          }
        }

        &.arrivalDateBefore {
          padding-right: 10px;
          @media (max-width: $screen-xs-max) {
            margin-bottom: 30px;
          }
        }

        &.DepartureDateAfter {
          padding-left: 10px;
        }
      }
    }

    .v-select {
      margin-bottom: 30px;
      position: relative;
      @media (max-width: $screen-xs-max) {
        margin: 0 20px 30px;
      }

      .vs__actions {
        display: flex;
        align-items: center;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        svg {
          transform: scale(1);
          transition: transform .30s cubic-bezier(1, -.115, .975, .855);
          transition-timing-function: cubic-bezier(1, -.115, .975, .855);
        }
      }

      &.vs--open {
        .vs__actions {
          svg {
            transform: rotate(180deg) scale(1);
          }
        }
      }

      .vs__selected-options {
        height: auto;
        min-height: 40px;
        flex-basis: unset;
        flex-grow: unset;
        width: calc(100% - 20px);

        input, .vs__selected {
          font-family: $font-family-primary;
        }


        .vs__selected {
          padding: 0 10px;
          background: $form-rooming-modal-pastille-background;
          color: $form-rooming-modal-pastille-color;
          margin-right: 5px;
          white-space: nowrap;
          height: 33px;
          margin-top: 3px;
          display: flex;
          align-items: center;
          border-radius: $form-input-radius;

          button {
            background: none;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1px 0 1px 10px;

            svg {
              path {
                fill: $form-rooming-modal-pastille-color;
              }
            }
          }
        }
      }

      .vs__dropdown {
        &-toggle {
          border-radius: $form-input-radius;
          padding-left: 2Px;
          height: auto;
          min-height: 40px;

          .vs__selected-options {
            border-radius: $form-input-radius;

            input {
              border-radius: $form-input-radius;
            }

            .vs__selected + input {
              width: auto;
            }
          }
        }

        &-menu {
          position: absolute;
          z-index: 1000;
          width: 100%;
          background: white;
          box-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);

          li {
            cursor: pointer;
            font-family: $form-components-font-family;
            padding: 10px 10px;
            background: $form-rooming-modal-items-list-background;
            color: $form-rooming-modal-items-list-color;

            &:hover {
              background: $form-rooming-modal-items-list-background-hover;
              color: $form-rooming-modal-items-list-color-hover;
            }
          }
        }
      }
    }

    .roomDatePicker {
      table {
        thead {
          th {

          }
        }
      }
    }

    .submit {
      background: $dark-blue;
      border: 2px solid $dark-blue;
      margin: 30px auto 0;
      @media (max-width: $screen-xs-max) {
        margin: 30px auto;
      }

      &:hover {
        background: $black;
        border: 2px solid $black;
      }

      &[disabled="disabled"] {
        background: $grey;
        cursor: auto;
        border: 2Px solid $grey;
      }
    }


  }

  &-hotels {
    @media (max-width: $screen-xs-max) {
      padding: 0 20px;
    }

  }

}
