.sidenav {
  padding: 85px 20px 0 20px;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #37393F;
  transform: translateX(-105%);
  z-index: 502;
  transition-duration: 500ms;
  @media only screen and (max-width: $screen-md-max) {
    max-width: 50%;
  }
  @media only screen and (max-width: $screen-xs-max) {
    max-width: 100%;
  }

  &.open {
    transform: translateX(0);
    transition-duration: 500ms;
  }

  .sidenav-close {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 50px;
    height: 50px !important;
    margin-bottom: 5px;
    z-index: 1;

    svg {
      display: flex;
      width: 100%;
      height: auto;
    }
  }
}

.sidenav-mobile {
  transform: translateX(0) !important;

  .sidenav-close {
    display: none;
  }

  @media only screen and (min-width: $screen-lg-min) {
    .header-cta-inscription {
      display: none;
    }
  }

  @media only screen and (max-width: $screen-md-max) {
    padding: 85px 20px 0 20px;
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: $sidenav-mobile-background-color;
    transform: translateX(-105%) !important;
    z-index: 502;
    transition-duration: 500ms;
    align-items: flex-start;
    &.open {
      transform: translateX(0) !important;
      transition-duration: 500ms;
    }

    .sidenav-close {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 60px !important;
      margin-bottom: 5px;
      z-index: 1;
      cursor: pointer;


      svg {
        display: flex;
        width: 100%;
        height: auto;

        path {
          stroke: $sidenav-mobile-cross-color;
        }
      }

      &:hover {
        svg path {
          stroke: $sidenav-mobile-cross-color-hover;
        }
      }

    }
  }

  &[data-content="header-nav"] {
    @media only screen and (max-width: $screen-md-max) {
      max-width: 50%;
      flex-direction: column;
    }
    @media only screen and (max-width: $screen-xs-max) {
      max-width: 100%;
      overflow: scroll;
      padding-bottom: 50px;
    }
  }

}
