.programme {

  &-wrapper {
    padding: 130px 40px;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media (max-width: $screen-sm-max) {
      padding: 100px 40px;
    }
    @media (max-width: $screen-xs-max) {
      padding: 50px 20px;
    }
  }

  &-title {
    background: $programme-bandeau-background-color;
    color: $programme-bandeau-text-color;
    margin-top: -4px;
  }

  &-content {
    max-width: 940px;
    margin: 0 auto;
    padding: 0;
    background: rgba(93, 113, 122, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 294px;
    @media (max-width: $screen-sm-max) {
      padding: 50px 0;
    }
    @media (max-width: $screen-xs-max) {
      padding: 50px 0;
      min-height: 224px;
    }

    p {
      font-size: 24px;
      line-height: 28px;
      color: $white;
      text-transform: uppercase;
      text-align: center;
      padding: 0 20px;
      @media (max-width: $screen-xs-max) {
        font-size: 18px;
        line-height: 24px;
        b {
          font-size: 18px;
          line-height: 24px;
        }
        br {
          display: none;
        }
      }

      span {
        display: block;
        padding-top: 10px;
        font-size: 20px;
        @media (max-width: $screen-xs-max) {
          font-size: 17px;
          line-height: 22px;
        }

      }


    }

  }
}
