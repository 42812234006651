.loader-dots-container {
  width: 100%;

  & > .loader-dots {
    margin: 0 auto;
    width: 88px;
    height: 42.2px;
    background: radial-gradient(circle closest-side, $form-loading-background 90%, transparent) 0 50%,
    radial-gradient(circle closest-side, $form-loading-background 90%, transparent) 50% 50%,
    radial-gradient(circle closest-side, $form-loading-background 90%, transparent) 100% 50%;
    background-size: calc(100% / 3) 21.1px;
    background-repeat: no-repeat;
    animation: loader-dots-7ar3yq 1.4s infinite linear;
  }

  @keyframes loader-dots-7ar3yq {
    15% {
      background-position: 0 0, 50% 50%, 100% 50%;
    }

    30% {
      background-position: 0 100%, 50% 0, 100% 50%;
    }

    45% {
      background-position: 0 50%, 50% 100%, 100% 0;
    }

    60% {
      background-position: 0 50%, 50% 50%, 100% 100%;
    }

    75% {
      background-position: 0 50%, 50% 50%, 100% 50%;
    }
  }

}
