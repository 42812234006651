.covid {
  &-title {
    background: $covid-bandeau-background-color;

    .h2 {
      color: $covid-bandeau-text-color;
    }
  }

  &-wrapper {
    padding: 0 50px;
    @media (max-width: $screen-xs-max) {
      padding: 0 20px;
    }
  }

  &-container {
    width: 100%;
    max-width: 992px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;

    &-wrapper {
      padding: 100px 0;
      width: 100%;
      @media (max-width: $screen-xs-max) {
        padding: 60px 0;
      }
    }
  }

  &-bloc {
    &-item-title {
      font-family: $font-family-primary;
      font-size: 16px;
      line-height: 19px;
      color: $third-color;

      &.monnaie {
        padding-top: 20px;
      }
    }

    &-title {
      h2 {
        text-transform: uppercase;
        color: #00205C;
        border-bottom: 1px solid #00205C;
        padding-bottom: 10px;
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 31px;
      }
    }

    &-text {
      p {
        a {
          color: #707070;
          overflow-wrap: anywhere;
          text-decoration: underline;


          &:hover {
            color: $secondary-color;
          }
        }
      }

      &.flex {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media (max-width: $screen-xs-max) {
          flex-direction: column;
        }
      }

      &-right, &-left {
        width: 50%;
        @media (max-width: $screen-xs-max) {
          width: 100%;
        }
      }

      &-right {
        padding-left: 30px;
        @media (max-width: $screen-xs-max) {
          padding: 20px 0 0 0;
        }
      }

      &-left {
        padding-right: 30px;
        @media (max-width: $screen-xs-max) {
          padding: 0;
        }

        .comparator {
          a {
            color: #00205C;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;

            &:hover {
              color: $third-color;
            }
          }

          span {
            color: #F9DE28;
            font-weight: bold;
          }
        }
      }
    }
  }


  &-entree {
    margin-bottom: 80px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 60px;
    }
  }
}

.covid-page {
  height: 100%;

  body {
    height: 100%;

    .layout-container {
      height: 100%;
      display: flex;
      flex-direction: column;

      main {
        flex-grow: 1;

        .layout-content, .layout-content .covid, .layout-content .covid .grid {
          height: 100%;
        }

        .col-12 {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
