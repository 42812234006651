.contact {
  &.with-background {
    background: $contact-background-color;
  }

  &.with-date-limite {
    .contact-wrapper {
      padding: $contact-with-date-limite-wraapper-padding;
    }
  }

  &.with-title {
    .block-title {
      display: block !important;

      h2 {
        color: $contact-block-title-color;
      }
    }
  }


  .h5, p, p strong, a {
    color: $contact-text-color;
  }

  a {
    &:hover {
      color: $contact-link-color-hover;
    }
  }

  &-wrapper {
    max-width: $contact-wrapper-max-width;
    margin: 0 auto;
    padding: $contact-wrapper-padding;

    @media (max-width: $screen-md-max) {
      max-width: $contact-wrapper-max-width-md;
    }
    @media (max-width: $screen-xs-max) {
      max-width: $contact-wrapper-max-width-xs;
      padding: $contact-wrapper-padding-xs;
    }
  }

  &-list {
    display: flex;
    justify-content: space-between;
    padding: $contact-list-padding;
    @media (max-width: $screen-md-max) {
      flex-flow: row wrap;
    }
    @media (max-width: $screen-xs-max) {
      flex-direction: column;
    }

    & > div:not(.logo) {
      padding-right: 40px;
      @media (max-width: $screen-md-max) {
        padding-right: 0;
      }
    }

    // TODO A VOIR
    &.small-marge {
      justify-content: center;
    }


    .contact-item {
      .contact-infos {
        p:first-child {
          padding-bottom: $contact-item-infos-p-first-padding-bottom;
        }
      }
    }
  }

  &-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    order: 2;
    width: 50%;
    text-align: $contact-item-text-align;
    //@media (max-width: $screen-md-max) {
    //  width: 70%;
    //}
    //@media (max-width: $screen-sm-max) {
    //  width: 100%;
    //}
    @media (max-width: $screen-xs-max) {
      width: 100%;
    }

    h3 {
      margin-bottom: $contact-item-h3-margin-bottom;
      @media (max-width: $screen-md-max) {
        margin-bottom: $contact-item-h3-margin-bottom-md;
      }
    }

    &:not(:last-child) {
      @media (max-width: $screen-xs-max) {
        margin-bottom: $contact-list-gap;
      }
    }

    // ITEM BLOCK LOGO
    &.logo {
      display: flex;
      flex-grow: 1;
      align-items: center;
      @media (max-width: $screen-sm-max) {
        align-items: flex-start;
      }

      &.separator {
        position: relative;
        @media (max-width: $screen-md-max) {
          width: 50%;
        }

        &:before {
          content: attr(data-before);
          position: absolute;
          right: var(--right);
          left: var(--left);
          transform: translateX(-50%);
          height: 100%;
          width: 1px;
          background: $black;
          @media (max-width: 1375px) {
            display: none;
          }
        }
      }
    }
  }

  &-title-container {
    width: 100%;
    padding-bottom: $contact-item-title-padding-bottom;
    @media (max-width: $screen-xs-max) {
      padding-bottom: $contact-item-title-padding-bottom-xs;
    }

    h3 {
      padding: $contact-item-title-h3-padding;
      background: $contact-item-title-h3-background;
      width: $contact-item-title-h3-width;
      margin: 0 auto;
    }
  }

  &-infos {
    width: $contact-item-infos-width;
    @media (max-width: 1100px) {
      width: $contact-item-infos-width-md;
    }
    @media (max-width: $screen-sm-max) {
      width: 100%;
    }

    p {
      font-size: $contact-item-infos-p-font-size;

      a {
        font-size: $contact-item-infos-a-font-size;
        font-weight: $contact-item-infos-a-font-size;
      }
    }


    // TODO WITH-ADRESS
    &.with-adress {
      .contact-adress {
        padding-bottom: 38px;
        @media (max-width: $screen-xs-max) {
          padding-bottom: 20px;
        }
      }
    }

    // TODO WITHOUT-ADRESS
    &:not(.with-adress) {
      .contact-title {
        padding-bottom: 60px;
        @media (max-width: $screen-sm-max) {
          padding-bottom: 20px;
        }
      }
    }


    // TODO A VOIR NOTA-BENE
    .nota-bene {
      padding-top: 15px;
      font-size: 12px;
      font-style: italic;
      font-weight: $font-weight-normal;
      @media (max-width: $screen-md-max) {
        max-width: 380px;
        margin: 0 auto;
      }

      .return {
        display: block;
        @media (max-width: $screen-md-max) {
          display: initial;
        }
      }
    }
  }

  // TODO A VOIR
  &-multiple-with-logo {
    .contact-wrapper {
      @media (max-width: 1300px) {
        max-width: unset;
      }
    }
  }
}
