// ******* CONTACTS *********** // Pas de choix tout est gérer en js dans js/page/PageHome

//.Contact // CAN add ' .with-title ' && ' .with-background ' && ' .with-date-limite '
//.Contact .with-background
$contact-with-background-color: rgba(140, 186, 229, 0.2);
//.Contact .with-date-limite
$contact-with-date-limite-wraapper-padding: 80px 0 40px;
$contact-block-title-color: $red;

//&-wrapper // Can add .with-logo
$contact-wrapper-padding: 50px 50px;
$contact-wrapper-padding-xs: 40px 20px;
$contact-wrapper-max-width: 1384px;
$contact-wrapper-max-width-md: 960px;
$contact-wrapper-max-width-xs: unset;

//&-list
$contact-list-padding: 0 20px;

//&-item //Can add .separator to .logo (is change dynamiquement js/page/PageHome)
//Add .logo for block logo !!
$contact-item-text-align: center;
//gap on each //sm
$contact-list-gap: 30px;
//&-item .logo
//h3
$contact-item-h3-margin-bottom: 0;
$contact-item-h3-margin-bottom-md: 20px;

//&-title
$contact-item-title-padding-bottom: 20px;
$contact-item-title-padding-bottom-xs: 10px;
//h3
$contact-item-title-h3-padding: 10px 5px;
$contact-item-title-h3-background: $red;
$contact-item-title-h3-width: fit-content;

//&-infos
$contact-item-infos-width: 45%;
$contact-item-infos-width-md: 50%;
$contact-item-infos-width: 45%;
$contact-item-infos-width: 45%;
//:first-child
$contact-item-infos-p-first-padding-bottom: 5px;
//p
$contact-item-infos-p-font-size: 14px;
//a
$contact-item-infos-a-font-size: 14px;
$contact-item-infos-a-font-size: $font-weight-regular;
