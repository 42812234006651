#menuToggle {
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  height: auto;
  max-height: 36px;
  display: block;

  input {
    display: block;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
    height: 23px;
    top: 0px;
    left: 0px;
    width: 33px;
    margin: 0;
  }

  span {
    display: block;
    position: relative;
    background: $header-text-color;
    z-index: 1;
    width: 33px;
    height: 3px;
    margin-bottom: 7px;
    top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
