.basic-page {


  &-container {
    padding: 120px 50px;
    @media (max-width: $screen-md-max) {
      padding: 130px 15px 80px;
    }
    @media (max-width: $screen-sm-max) {
      padding: 130px 30px 50px;
    }
    @media (max-width: $screen-xs-max) {
      padding: 50px 20px 50px;
    }
  }

  &-wrapper {
    margin: 0 auto;
    max-width: 1264px !important;
    @media (max-width: $screen-md-max) {
      max-width: 800px;
    }
  }

  h1 {
    padding-bottom: 30px;
    text-transform: uppercase;
    font-weight: 600;
    @media (max-width: $screen-xs-max) {
      padding-bottom: 20px;
    }
  }

  &-texte {
    h2 {
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;

      &.autorize {
        color: $black;
        padding: 30px 0 0 0 !important;
        font-size: 16px;
        line-height: 20px;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20Px;

      .return {
        display: block;
      }

      a {
        font-size: 16px;
        line-height: 20Px;
        font-weight: 400;
      }
    }

    ul {
      list-style: none;
      padding: 10px 0 0;
      margin: 0 0 0 20px;
      @media (max-width: $screen-xs-max) {
        margin: 0 0 0 20px;
      }

      li {
        font-family: $font-family-primary;
        position: relative;
        padding-left: 10px;
        padding-bottom: 0;
        font-size: 16Px;
        line-height: 20px;
        font-weight: 400;
        color: $primary-color;

        &:before {
          content: '';
          width: 4px;
          height: 4px;
          position: absolute;
          border-radius: 100%;
          top: 7px;
          left: 0;
          background: $primary-color;
        }
      }

      &.list-bigger {
        padding: 30px 0 0;

        li {
          padding-bottom: 10px;
        }
      }
    }
  }

  .page-confidentialite {
    h2, h3 {
      padding-top: 30px;
    }

    h3 {
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
    }

    p {
      padding-top: 10px;

      .return {
        padding-top: 10px;
      }

      a {
        color: $grey;

        &.mail {

          &.inline {
            display: inline-block;
          }
        }
      }

      &.texte-bigger {
        font-size: 16px;
        line-height: 18Px;
        font-weight: 400;
      }
    }
  }

  .page-mentions-legales {
    h2 {
      padding: 30px 0 20px 0;
    }

    p {
      padding-top: 10px;

      .return {
        padding-top: 10px;
      }

      a {
        color: $grey;

        &:hover {
          color: $gold;
        }
      }
    }
  }
}
