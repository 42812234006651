.recap-block {
  margin-top: 120px;

  &-header {
    text-align: center;

    h3 {
      position: relative;
      display: inline-block;
      color: $form-recap-title-block-color;
      padding-bottom: 15px;
      text-transform: uppercase;
      font-size: 22px;
      line-height: 26px;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
        height: 3Px;
        background: $form-recap-title-block-border-color;
      }
    }
  }

  &-content {
    color: $form-components-color;

    div {
      font-family: $form-components-font-family;
      font-size: 14px;
      line-height: 20px;
    }

    .recap-bold {
      color: $form-recap-color-bold;
      font-weight: 500;
    }

    .bold {
      .recap-bold {
        font-weight: 700;
      }

      .participation {
        text-transform: lowercase;
      }
    }

    .clickable {
      cursor: pointer;

      &:hover {
        color: $form-recap-color-bold-hover;
      }
    }

    .recap-block {
      &-container {
        padding-top: 30px;
      }

      &-date {
        padding-bottom: 30px;
        color: $black;

      }

      &-agency, &-participants, &-accompagnants, &-rooms {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -10px;
        @media only screen and (max-width: $screen-xs-max) {
          margin: 0;
        }


        h4 {
          color: $form-recap-color-bold;
          width: 100%;
          padding: 0 10px 30px;
        }


        .recap-block-participant, .recap-block-room, {


          &-name, &-number {
            padding-bottom: 10px;
            text-transform: uppercase;
            margin-bottom: 10px;
            border-bottom: 1px solid $form-recap-separator-color;

            span {
              font-size: 14px !important;
              line-height: 18px;
            }
          }

          &-complete-infos {
            padding-bottom: 7px;

            > div {
              padding-bottom: 3px;

              &:last-child {
                padding-bottom: 0;
              }
            }
          }

          &-list {
            &-accompagnants, &-occupants {
              display: block;
            }

            &-parent {
              .clickable {
                display: block;
              }
            }
          }
        }

        .recap-block-room {
          &-complete-infos {
            padding-bottom: 0;
          }
        }
      }

      &-accompagnants {
        padding-top: 30px;
      }


      &-participant, &-room, &-agency {
        position: relative;
        padding: 0 10px;
        width: 33.33%;
        margin-bottom: 20px;
        @media only screen and (max-width: $screen-sm-max) {
          width: 50%;
        }
        @media only screen and (max-width: $screen-xs-max) {
          width: 100%;
          padding: 0;
        }

        &-wrapper {
          background: #ffffff;
          border-radius: $form-block-radius;
          box-shadow: $form-block-shadow;
          padding: 20px !important;
          height: 100%;
          transition-duration: 400ms;

          &.highlight {
            transform: scale(1.1);
            transition-duration: 400ms;
            z-index: 11;
            position: relative;
            border: 1px solid darken($form-recap-separator-color, 40%);
            @media only screen and (max-width: $screen-xs-max) {
              transform: scale(1.05);
            }

          }
        }

        /*
        margin-top: 15px;
        padding-top: 15px;

        &:before {
          content: '';
          top: -2px;
          left: 0;
          position: absolute;
          width: 20px;
          height: 4px;
          background: $form-recap-color-bold;
        }

         */

        &-identity-doc, &-transport {
          padding-top: 10px;
          margin-top: 10px;
          border-top: 1px solid $form-recap-separator-color;

          h5 {
            padding-bottom: 5px;
            text-decoration: underline;
            font-size: 14px;
            line-height: 18px;
            color: $form-recap-color-bold;
          }
        }
      }

      &-agency {
        width: 50%;
        padding-bottom: 30px;
        @media only screen and (max-width: $screen-xs-max) {
          width: 100%;
        }

        &-wrapper {
          width: 100%;
        }

        .recap-block-agency {
          &-title {
            padding-bottom: 10px;
            text-transform: uppercase;
            margin-bottom: 10px;
            border-bottom: 1px solid $form-recap-separator-color;

            span {
              font-size: 14px !important;
              line-height: 18px;
            }
          }

          &-complete-infos {
            padding-bottom: 7px;

            > div {
              padding-bottom: 3px;

              &:last-child {
                padding-bottom: 0;
              }
            }
          }
        }
      }

      &-rooms {
        margin-top: 30px;
      }
    }

    .total-registration-price {
      display: flex;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
