.c-cross {
  position: relative;

  &:before, &:after {
    position: absolute;
    content: '';
    display: block;
    top: 50%;
    border-color: $primary-color;
    left: 0;
    width: 0;
    border-right: 1px solid;
    height: 50px !important;
    margin-left: 15px;
    transform: rotate(222deg);
    @media (max-width: $screen-xs-max) {
      height: 30px !important;
      margin-left: 15px;
    }
  }

  &:after {
    margin-left: 25px;
    margin-top: 1px;
    transform: rotate(-45deg);
    @media (max-width: $screen-xs-max) {
      margin-left: 15px;
    }
  }

}
