.edito {
  position: relative;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: $screen-xs-max) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .decoration {
    position: absolute;
    right: -4%;
    top: 0;
    z-index: -1;
    @media (max-width: 1650px) {
      right: -6%;
    }
    @media (max-width: 1600px) {
      right: -10%;
    }
    @media (max-width: 1470px) {
      right: -9%;
      top: -150px;
    }
    @media (max-width: 1300px) {
      right: -5%;
      top: -240px;
    }
    @media (max-width: $screen-md-max) {
      right: -10px;
      top: 0;
    }
    @media (max-width: $screen-xs-max) {
      right: -10px;
      top: -150px;
    }

  }

  &-container {
    padding-top: 97px;
    @media (max-width: $screen-xs-max) {
      padding: 50px 0 0;
    }

    &.one-column {
      display: flex;
      flex-direction: column;

      .edito-slider {
        @media (max-width: $screen-xs-max) {
          width: 100%;
        }
      }
    }

    &.two-columns {
      display: flex;
      flex-direction: row-reverse;
      max-width: 1264px;
      margin: 0 auto;
      margin-bottom: 102px;
      @media (max-width: $screen-md-max) {
        padding-top: 80px;
        max-width: 880px;
        flex-direction: column-reverse;
        margin-bottom: 80px;
      }

      > div {

        &.edito-image {
          padding-right: 10px;
          display: flex;
          width: 50% !important;
          @media (max-width: $screen-md-max) {
            padding-right: 0;
            width: 100% !important;
          }

          .slider-container {
            width: 100%;
          }
        }

        &.edito-descr {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 50px;
          width: 50% !important;
          background: white;
          z-index: 1;
          @media (max-width: $screen-md-max) {
            padding-left: 0;
            width: 100% !important;
            margin-top: 40px;
          }

          p {
            text-align: left;
            @media (max-width: $screen-md-max) {
              text-align: center;
            }

            &:first-child {
              padding-top: 0;
            }
          }

          .obligatory-passeport, .covid {
            background: $gold;
            display: flex;
            width: 151Px;
            height: 61Px;
            padding: 14px 19px 12px 19px;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            margin-top: 45px;
            @media (max-width: $screen-xs-max) {
              margin: 45px auto 0;
            }


            svg {
              position: absolute;
              top: -22Px;
              right: -17px;
            }

            p {
              font-family: $font-family-arial;
              font-size: 14px;
              line-height: 19px;
              color: $black;
              font-weight: $font-weight-bold;
              text-transform: uppercase;
              padding: 0;
              @media (max-width: $screen-md-max) {
                text-align: left;
              }
            }
          }
        }
      }

      .edito-slider {
        @media (max-width: $screen-xs-max) {
          width: 100%;
        }
      }
    }
  }

  &-descr {
    text-align: center;
    max-width: 850px;
    margin: 0 auto;

    .picto-align {
      display: flex;
      justify-content: space-between;
      @media (max-width: $screen-md-max) {
        justify-content: center;
      }
      @media (max-width: $screen-xs-max) {
        flex-direction: column;
      }


      .covid {
        width: 165Px !important;
        height: auto !important;
        @media (max-width: $screen-md-max) {
          margin-left: 70px;
          width: 192Px !important;
        }
        @media (max-width: $screen-xs-max) {
          margin: 30px auto 0 !important;
        }


        p {
          font-size: 12px !important;
          line-height: 14px !important;
          text-transform: initial !important;
        }

      }
    }

    img {
      max-width: 260px;
      height: auto;
      margin: 20px 0;
      @media (max-width: $screen-xs-max) {
        margin: 0;
        max-width: 220px;
      }
    }

    .with-separation {
      position: relative;
      padding-bottom: 10px;
      text-align: left;
      font-size: 20px;
      line-height: 25px;
      @media (max-width: $screen-md-max) {
        padding-bottom: 10px;
        text-align: center;
      }

      &:before {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 0;
        background: $gold;
      }

      @media (max-width: $screen-xs-max) {
        br {
          display: none;
        }
      }
    }

    p {
      position: relative;
      padding-top: 20px;
      @media (max-width: $screen-xs-max) {
        line-height: 22px;
      }

      &.weight-normal {
        font-weight: $font-weight-normal;
      }

      em {
        font-size: 12px;
        line-height: 10px;
      }

      span {
        &.blue {
          color: $blue;
          font-size: 26px;
          line-height: 32px;
          @media (max-width: $screen-sm-max) {
            font-size: 24px;
          }
          @media (max-width: $screen-xs-max) {
            font-size: 18px;
            line-height: 24px;
          }
        }

        &.blue {
          color: $blue;
        }

        &.red {
          color: $red;
        }

        &.black {
          color: $red;
        }

        &.weight-normal {
          font-weight: $font-weight-normal;
        }

        &.return {
          display: block;
          line-height: 13px;
          @media (max-width: $screen-xs-max) {
            display: inherit;
            padding-left: 4px;
          }
        }
      }
    }

    .date-limite {
      position: relative;
      bottom: unset !important;
      left: unset;
      transform: unset;
      max-width: 170px;
      margin-top: 20px;
      @media (max-width: $screen-md-max) {
        margin: 40px auto;
      }

    }
  }

  &-image {
    img {
      width: 100%;
      height: auto;
    }
  }

  &-slider {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 40px;
    @media (max-width: $screen-sm-max) {
      max-width: 600px;
    }
    @media (max-width: $screen-xs-max) {
      max-width: unset;
    }

    img {
      width: 100%;
    }

    .slick-arrow {
      &.slick-prev, &.slick-next {
        &:before, &:after {
          border-right: 3px solid $blue !important;
        }

        &:before {
          margin-top: -13px !important;
        }
      }

      &.slick-prev {
        left: -50px !important;
      }

      &.slick-next {
        right: -50px !important;
      }

      @media (max-width: $screen-xs-max) {
        display: none !important;
      }
    }

    .slick-dots {
      margin: 40px 0 0 0;
    }
  }
}
