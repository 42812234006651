/* IMPORTS */

// VARIABLES
@import "abstracts/variables/variables-commons";
@import "abstracts/variables/variables-form";
@import "page/login/variables-login-page";
@import "page/one-page/variables-one-page";
@import "page/basic-page/variables-basic-page";
@import "page/registration/variables-registration";
@import "page/payment/variables-payment";

// COMPONENTS
@import "components/buttons";
@import "components/cross";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "components/burger-btn";
@import "components/modal";
@import "components/slider";
@import "components/sidenav";
@import "components/form/form-commons";
@import "components/form/form-components";

// LOADER
@import "components/loader/btn-loader";
@import "components/loader/form-loader";

/*
// INSCRIPTION
@import "components/registration/form-block";
@import 'components/registration/registration-common';
@import 'components/registration/registration-activities';
@import 'components/registration/registration-participating-block';
@import 'components/registration/registration-agency-info';
@import 'components/registration/registration-participant-block';
@import 'components/registration/registration-programme-accompagnant';
@import 'components/registration/registration-recap-block';
@import 'components/registration/registration-eco-tree';
@import 'components/registration/registration-agency-dating';
@import 'components/registration/participant-guest-block';
@import 'components/registration/registration-party';
@import 'components/registration/registration-dating';
@import 'components/registration/registration-friend-block';
@import 'components/registration/registration-centralizer';
@import 'components/registration/registration-accept-video';
@import 'components/registration/registration-flight-block';

// ROOMING MODULE
@import 'components/registration/registration-room-modal';
@import 'components/registration/registration-room-block';
*/
// LAYOUT
@import "layout/header";
@import "layout/footer";
@import "layout/popin-rgpd";

// BLOCKS

// PAGE LOGIN
@import 'page/login/login';

// PAGE REGISTRATION
@import 'page/registration/registration-commons';
@import 'page/registration/blocks/registration-agency-infos';
@import 'page/registration/blocks/registration-participant';
@import 'page/registration/blocks/registration-participant-documents';
@import 'page/registration/blocks/registration-add-guest-section';
@import 'page/registration/blocks/registration-transport';
@import 'page/registration/blocks/registration-participation-pack';
@import 'page/registration/blocks/registration-room-section';
@import 'page/registration/blocks/registration-room-block';
@import 'page/registration/blocks/registration-room-modal';
@import 'page/registration/blocks/registration-placement';
@import 'page/registration/blocks/registration-billing';
@import 'page/registration/blocks/registration-payment-method';
@import 'page/registration/blocks/registration-centralizer';
@import 'page/registration/blocks/registration-recap';
@import 'components/inscription/inscription-activities';

// REGISTRATION END
@import 'page/registration-end/variables-registration-end';
@import 'page/registration-end/registration-end';

// PAYMENT
@import 'page/payment/blocks/payment-cb';

// ONE PAGE

//Hero
@import "page/one-page/blocks/activite/variables-activite";
@import "page/one-page/blocks/activite/activite";

@import "page/one-page/blocks/hero/variables-hero";
@import "page/one-page/blocks/hero/hero";

@import "page/one-page/blocks/date-limite/variables-date-limite";
@import "page/one-page/blocks/date-limite/date-limite";

@import "page/one-page/blocks/edito/variables-edito";
@import "page/one-page/blocks/edito/edito";

@import "page/one-page/blocks/hebergement/variables-hebergement";
@import "page/one-page/blocks/hebergement/hebergement";
@import "page/one-page/blocks/hebergement/hotel";

@import "page/one-page/blocks/infos-pratiques/variables-infos-pratiques";
@import "page/one-page/blocks/infos-pratiques/infos-pratiques";

@import "page/one-page/blocks/contact/variables-contact";
@import "page/one-page/blocks/contact/contact";

@import "page/one-page/blocks/programme/variables-programme";
@import "page/one-page/blocks/programme/programme";

@import "page/one-page/blocks/tarifs/variables-tarifs";
@import "page/one-page/blocks/tarifs/tarifs";

// ONE PAGE a voir
@import "page/one-page/blocks/a-voir/hotel";
@import "page/one-page/blocks/a-voir/map";
@import "page/one-page/blocks/a-voir/mission";

@import "page/one-page/blocks/sub-footer-brand";
@import "page/one-page/blocks/open-later";

@import "page/mes-inscriptions";
@import 'page/basic-page/basic-page';
@import './page/error';
@import "page/payment";
@import './page/covid';
@import './page/maintenance-page';

// ABSTRACTS
@import "abstracts/utilities";
@import "abstracts/commons";

// VUE JS
@import 'vue/transitions';
