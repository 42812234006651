.payment-cb {

  .layout-content {
    background: $registration-background-layout;
  }

  .registration-end {
    &-title {
      background: none;
      display: flex;
      justify-content: center;
      background: $registration-end-bandeau-title-background !important;

      h2 {
        width: 100%;
        padding: 17px 20px 15px;
        color: $registration-end-bandeau-title-color !important;

        &:before {
          display: none;
        }
      }
    }

    &-message {
      padding: 30px 50px 50px;
      @media (max-width: $screen-xs-max) {
        padding: 30px 20px 50px;
      }
    }
  }

  &-content {
    max-width: 1284px;
    margin: 0 auto;
    padding: 25px 0 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: $screen-sm-max) {
      padding: 50px 0;
    }

    h3 {
      margin-bottom: 20px;
      font-family: $font-family-primary;
      text-align: center;
      letter-spacing: 0;

      span {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;

        b {
          font-weight: 600;
        }

        &.agency {
          padding-bottom: 10px;
          display: block;
          text-transform: uppercase;

          .agency-billing-name {
            font-weight: 400;
            text-transform: initial;
          }
        }

        &.price {
          display: block;
          padding-bottom: 50px;

          b {
            color: $payment-cb-price-color;

          }
        }
      }
    }

    > div {
      @media (max-width: $screen-xs-max) {
        width: 100%;
      }

      &.kr-embedded {
        display: flex;
        flex-flow: row wrap;
        width: 366px;
        @media (max-width: $screen-xs-max) {
          width: 100%;
        }

        .kr-pan, .kr-expiry, .kr-security-code {

          height: $form-input-height;
          border-radius: $form-input-radius;
          border: $form-input-border;
          font-family: $form-components-font-family;
          font-size: $form-input-font-size;
          line-height: $form-input-line-height;
          font-style: $form-input-font-style;
          color: $form-components-color;
          margin-bottom: 20px;

          .kr-field-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            .kr-help-button-wrapper {
              display: flex;
              align-items: center;
            }
          }

          .kr-iframe-wrapper {
            padding-left: 2px;
          }
        }

        .kr-pan {
          width: 100%;
        }
      }

      .kr-expiry, .kr-security-code {
        width: calc(50% - 10px);
      }

      .kr-expiry {
        margin-right: 10px;
      }

      .kr-security-code {
        margin-left: 10px;
      }

      .kr-payment-button {
        width: unset !important;
        margin: 20px auto 0;
        padding: $buttons-padding;
        border-radius: $buttons-radius;
        height: $buttons-height;
        min-height: $buttons-height;
        max-height: $buttons-height;
        background: $registration-end-cta-background !important;
        color: $registration-end-cta-color !important;
        border: $registration-end-cta-border !important;
        box-shadow: $buttons-shadow;

        &:hover {
          background: $registration-end-cta-background-hover !important;
          color: $registration-end-cta-color-hover !important;
        }

        span {
          font-size: $buttons-font-size;
          line-height: $buttons-line-height;
          font-family: $buttons-family;
          font-weight: bold;
        }
      }

      .kr-form-error-visible {
        background-color: unset !important;
        margin: 0 auto;
        padding-top: 10px;

        span {
          color: red !important;
          padding: 0 !important;
          font-family: $font-family-primary;
          font-weight: 400 !important;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

  }

  .registration-end-cta {
    display: none;
  }

  &.without-bandeau {
    .registration-end-title {
      background: none;
      display: flex;
      justify-content: center;
      background: none !important;

      h2 {
        position: relative;
        color: $registration-end-bandeau-title-color-without-bandeau !important;
        padding: 100px 50px 25px;
        width: auto;

        &:before {
          display: block;
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 30%;
          height: 4Px;
          background: $registration-end-bandeau-title-border-without-bandeau;
        }
      }
    }
  }
}
