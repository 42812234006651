.contact {
  &-title {
    background: $contact-background-color !important;

    h2 {
      color: $contact-title-color;
    }
  }

  .container-wrapper {
    padding-right: 40px !important;
    padding-left: 40px !important;
    background: rgba(227, 219, 210, 0.3);
    @media (max-width: $screen-xs-max) {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
  }

  &-container {
    max-width: 1255px;
    margin: 0 auto;
    padding: 40px 0 60px;
    display: flex;
    @media (max-width: $screen-md-max) {
      flex-flow: row wrap;
      justify-content: center;
      padding: 40px 0 80px;
    }
  }

  &-item {
    width: 33.33%;
    @media (max-width: $screen-md-max) {
      width: 50%;

      &.hotels {
        order: 1;
      }
      &.access {
        order: 3;
        width: 75%;
      }
      &.ilt {
        order: 2;
      }
    }
    @media (max-width: $screen-sm-max) {
      &.access {
        margin-top: 30px;
      }
    }

    @media (max-width: $screen-xs-max) {
      width: 100%;
      &.hotels {
        order: 1;
      }
      &.access {
        order: 2;
        width: 100%;
      }
      &.ilt {
        order: 3;
      }
    }


    &.luz, &.ilt {
      .big-marge {
        padding-top: 38px;
        @media (max-width: $screen-xs-max) {
          padding-top: 21px;
        }
      }

      @media (max-width: $screen-xs-max) {
        margin-top: 30px;
      }
    }

    &-wrapper {
      padding: 0 10px;
    }

    &-header {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 328px;
        height: 1.5px;
        background: $black;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: $screen-xs-max) {
          width: 100%;
        }
      }

      .contact-item {
        &-logo {
          display: flex;
          justify-content: center;
          height: 68px;
          align-items: center;
        }

        &-title {
          padding-bottom: 10px;

          h3 {
            font-family: $font-family-brandon;
            font-size: 16px;
            line-height: 23px;
            font-weight: bold;
            color: $black;
            text-transform: uppercase;
            text-align: center;
          }
        }
      }
    }

    &-content {
      padding-top: 25px;

      &.acces {
        > p {
          text-align: center;
          font-size: 14px;
          line-height: 18px;
        }

        .title {
          font-size: 13px;
          padding-bottom: 20px;
        }

        ul {
          padding-top: 5px;

          li {
            font-family: $font-family-brandon;
            font-size: 14px;
            line-height: 20px;
            color: $grey;
            text-align: center;
            font-weight: bold;
            padding-bottom: 10px;

            span {
              font-weight: 400;
              display: block;
            }
          }
        }
      }

      &.contact {
        .title, .mail {
          font-weight: normal;

          a {
            &:hover {
              color: $black;
            }
          }
        }

        .mail {
          padding-top: 22Px;
        }
      }

      &-elt {

        p, a {
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          font-weight: 400;
          color: $grey;
          font-family: $font-family-brandon;

          &.title {
            font-weight: bold;

            &.big {
              font-family: $font-family-brandon;
              text-transform: uppercase;
              font-size: 13px;
            }
          }
        }

        a {
          &:hover {
            color: $secondary-color;
          }
        }
      }
    }

  }
}
