////****    PAGE LOGIN    ****////

//// Box
$login-box-width: 502px;
$login-box-background: $white;
$login-box-radius: 20px;

//// Box form
$login-box-form-width: 388px;
$login-box-form-padding: 40px 20px;

//// Header logo
$login-logo-margin-top: 30px 20px 30px;

//// First login
$login-first-connexion-text-size: 12px;
$login-first-connexion-text-line-height: 14px;
$login-first-connexion-margin: 40px auto 15px;

//// Already connected
$login-already-connected-text-size: 12px;
$login-already-connected-text-line-height: 14px;
$login-already-connected-link-color: $red;
$login-already-connected-link-color-hover: $blue;

//// Title
$login-title-with-bandeau-background: $blue;
$login-title-with-bandeau-color: $white;
$login-title-without-bandeau-background: $black;
$login-title-without-bandeau-color: $white;

//// Buttons
$login-buttons-width: 100%;

//// Form
$form-checkbox-label-font-size: 10px;
$form-checkbox-label-line-height: 14px;

//// Legal notice link
$login-legal-notice-link-size: 12px;

//// Show password
$show-pwd-color: $red;
$show-pwd-color-hover: $black;




