.sub-footer-brand{
  background: $sub-footer-brand-background-color;
  padding: 20px 0;
  box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
  &-container{
    display: flex;
    justify-content: center;
    img{
      width: 100px;
    }
  }
}