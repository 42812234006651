.page-registration {

  .layout-content {
    background: $registration-background-layout;
  }

  .registration {
    &-title {
      background: $registration-title-bandeau-background;
      width: 100%;
      max-width: $registration-title-max-width;
      margin: 0 auto;

      h2 {
        color: $registration-title-bandeau-color;
        padding: 23px 20px 17px;
        font-family: $font-family-montserrat;

        span {
          display: block;
          font-size: 15px;
          line-height: 22px;
          @media only screen and (max-width: $screen-xs-max) {
            font-size: 13px;
            line-height: 20px;
          }

        }
      }
    }

    &-accroche {
      padding: 80px 50px 50px;
      max-width: $form-accroche-max-width;
      margin: 0 auto;
      @media only screen and (max-width: $screen-xs-max) {
        padding: 50px 20px 20px;
      }

      > p {
        text-align: center;
        padding-bottom: 20px;

        a {
          white-space: nowrap;
        }

        &.last-p {
          position: relative;
          color: $dark-blue;

          a {
            color: $dark-blue;
          }

          svg {
            margin-right: 5px;
            display: inline-flex;
            padding-top: 4px;
            @media only screen and (max-width: $screen-xs-max) {
              display: none;
            }
          }
        }
      }

      .tableau-tarif {
        max-width: 639px;
        margin: 0 auto;
        padding-top: 20px;

        h3 {
          text-align: center;
          padding-bottom: 5px;
          text-transform: uppercase;
          color: $dark-blue;
        }

        &-content {
          border: 1px solid $gold;
          padding: 15px 24px 21px;
          @media (max-width: $screen-xs-max) {
            padding: 15px 15px 21px;
          }

          &-wrapper {
            &.convention {
              padding-bottom: 20px;
            }
          }

          p {
            text-align: left;
            padding-left: 0;
            color: $grey;
          }

          &-title, &-text {
            p {
              padding-bottom: 0;
            }
          }

          &-text {
            p {
              padding-bottom: 5px;

              &:last-child {
                padding-bottom: 0;
              }

              br {
                display: none;

                @media (max-width: $screen-xs-max) {
                  display: inline;
                }
              }
            }
          }

          &-title {
            padding-bottom: 5px;

            p {
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
            }
          }

          .modal-btn {
            cursor: pointer;

            &:hover {
              color: $gold;
            }
          }
        }
      }

      &-agencies {
        span {
          display: block;
          color: $form-accroche-agencies-list-color;
          font-weight: bold;
          padding-bottom: 5px;
        }
      }
    }

    &-wrapper {
      max-width: $registration-wrapper-max-width;
      margin: 0 auto;
      padding: 40px 50px 150px;
      @media only screen and (max-width: $screen-xs-max) {
        padding: 20px 20px 80px;
      }
    }

    &-obligatory {
      padding-bottom: 10px;

      p {
        font-size: 12px;
        font-weight: bold;
      }
    }

    &-block {
      width: 100%;
      box-shadow: $form-block-shadow;

      &-header {
        background-color: $form-block-header-background;
        position: relative;


        h2 {
          color: $form-block-header-color;
          text-transform: uppercase;
          text-align: center;
          font-size: $form-block-header-size;
          line-height: $form-block-header-line-height;
          @media only screen and (max-width: $screen-xs-max) {
            font-size: $form-block-header-size-mobile;
            line-height: $form-block-header-line-height-mobile;
            padding: 15px 20px 15px 45px;
          }
        }

        &-close {
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
          width: $form-block-header-close-size;
          height: $form-block-header-close-size;
          max-width: $form-block-header-close-size;
          max-height: $form-block-header-close-size;
          border-radius: $form-block-header-close-radius;
          background: $form-block-header-close-background;
          border: $form-block-header-close-border;
          font-size: $form-block-header-close-font-size;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $form-block-header-close-color;
          cursor: pointer;
          padding: 0 10px 5px;

          &:hover {
            border: $form-block-header-close-border-hover;
            color: $form-block-header-close-color-hover;
          }

          @media (max-width: $screen-xs-max) {
            left: 10px;
            width: $form-block-header-close-size-mobile;
            height: $form-block-header-close-size-mobile;
            max-width: $form-block-header-close-size-mobile;
            max-height: $form-block-header-close-size-mobile;
          }

          &.close {
            padding: 0px 10px 0;
          }
        }

        &-remove-item {
          position: absolute;
          right: -20px;
          top: -20px;
          width: $form-block-header-remove-size;
          height: $form-block-header-remove-size;
          max-width: $form-block-header-remove-size;
          max-height: $form-block-header-remove-size;
          border-radius: $form-block-header-remove-radius;
          border: none;
          background: $form-block-header-remove-background;
          font-size: $form-block-header-remove-font-size;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          appearance: none;
          padding: 0;
          @media (max-width: $screen-xs-max) {
            right: -15px;
            top: -15px;
            width: $form-block-header-remove-size-mobile;
            height: $form-block-header-remove-size-mobile;
            max-width: $form-block-header-remove-size-mobile;
            max-height: $form-block-header-remove-size-mobile;
          }

          &:hover {
            background: $form-block-header-remove-background-hover;

            svg {
              stroke: $form-block-header-remove-color-hover;
            }
          }

          svg {
            width: 28px;
            height: auto;
            stroke: $form-block-header-remove-color;
            @media (max-width: $screen-xs-max) {
              width: 20px;
            }
          }
        }
      }

      &-sub-title {
        p {
          color: $form-block-sub-title-color;
          font-weight: $form-block-sub-title-weight;
          font-size: $form-block-sub-title-size;
          line-height: $form-block-sub-title-line-height;
        }

        &-headline {
          p {
            font-size: $form-block-sub-title-headline-size;
            line-height: $form-block-sub-title-headline-line-height;
            font-weight: $form-block-sub-title-headline-weight;
            color: $form-block-sub-title-headline-color;
            font-style: italic;
          }
        }
      }

      &-content {
        padding: 30px 40px;
        background: #ffffff;
        border-bottom-left-radius: $form-block-radius;
        border-bottom-right-radius: $form-block-radius;
        @media (max-width: $screen-xs-max) {
          padding: 30px 20px;
          width: 100%;
        }
      }
    }

    &-section {
      width: 100%;
      padding-top: 30px;
      margin-top: 50px;
      border-top: 2px dotted $form-block-sub-title-dotted-color;

      &.registration-activity {
        .form-item-activity {
          .form-item-choice {
            label {
              line-height: 16px;

              br {
                display: none;

                @media (max-width: $screen-xs-max) {
                  display: inline;
                }
              }

            }
          }
        }
      }

      .registration-block-sub-title {
        margin-bottom: 30px;

        &-headline {
          padding-top: 5px;

          p {
            font-size: $form-block-sub-title-headline-size;
            line-height: $form-block-sub-title-headline-line-height;
            font-weight: $form-block-sub-title-headline-weight;
            color: $form-block-sub-title-headline-color;
            font-style: italic;
          }
        }
      }

      .registration-activities-recap {
        padding-bottom: 30px;
      }
    }

    &-warning {
      padding-bottom: 30px;

      p {
        text-align: center;
        margin-top: 80px;
        font-weight: bold;
      }
    }

    &-global-errors {
      padding-bottom: 10px;

      ul {
        li {
          text-align: center;
        }
      }
    }
  }

  .row-wrap-input-style, .absent-participant-form-section > div.form-item > div, .registration-block-content.row-wrap-input-style.participant-inputs .form-item-guest > div {
    display: flex;
    flex-flow: row wrap;
    @media only screen and (max-width: $screen-xs-max) {
      flex-direction: column;
    }

    > .form-item {
      width: 50%;
      @media only screen and (max-width: $screen-xs-max) {
        width: 100%;
      }

      &.form-item-remarks, &.form-item-dateOfBirth {
        margin-bottom: 0;
      }

      &.form-item.form-item-dateOfBirthday {
        margin-bottom: 20px;
      }

      &:nth-child(even) {
        padding-left: 7px;
        @media only screen and (max-width: $screen-xs-max) {
          padding-left: 0;
        }
      }

      &:nth-child(odd) {
        padding-right: 7px;
        @media only screen and (max-width: $screen-xs-max) {
          padding-right: 0;
        }
      }

      &.full-size, &.form-item-civility {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;

        > div {
          justify-content: flex-start;
          padding-top: 5px;
        }
      }
    }
  }

  .absent-participant-form-section > div.form-item > div {
    > .form-item {
      &:nth-child(odd) {
        padding-left: 7px;
        padding-right: 0;
        @media only screen and (max-width: $screen-xs-max) {
          padding-left: 0;
        }
      }

      &:nth-child(even) {
        padding-right: 7px;
        padding-left: 0;
        @media only screen and (max-width: $screen-xs-max) {
          padding-right: 0;
        }
      }
    }
  }

  .add-button, .submit-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 40%;

    .spinner-container {
      position: relative;
    }

    p {
      color: $form-components-color;
      font-weight: $font-weight-bold;
      text-align: center;
      padding-bottom: 20px;
    }

    button {
      width: $form-add-button-size !important;
      height: $form-add-button-size !important;
      min-width: $form-add-button-size !important;
      min-height: $form-add-button-size !important;
      max-width: $form-add-button-size !important;
      max-height: $form-add-button-size !important;
      border-radius: $form-add-button-radius;
      border: none;
      font-size: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 0;

      &:hover {
        background: $third-color;
      }
    }
  }

  // Buttons

  .registration-add-guest-section {
    .add-button button {
      background: $form-add-button-background-guest;
      color: $form-add-button-color-guest;

      &:hover {
        background: $form-add-button-background-guest-hover;
      }
    }
  }

  .registration-participant-container {
    > div:not(.registration-participant-item-container) {
      padding-top: 20px;
      display: block;

      .add-button button {
        background: $form-add-button-background-participant;
        color: $form-add-button-color-participant;

        &:hover {
          background: $form-add-button-background-participant-hover;
        }
      }
    }
  }

  .rooming-block {
    .add-button button {
      background: $form-add-button-background-rooming;
      color: $form-add-button-color-rooming;

      &:hover {
        background: $form-add-button-background-rooming-hover;
      }
    }
  }


  .registration-block-submit {
    display: flex;
    justify-content: center;

    .submit-wrapper {
      width: auto !important;
      min-width: auto !important;
      max-width: unset !important;
      position: relative;

      .loading-spinner-container {
        top: 50%;
      }

      button {
        min-width: auto !important;
        width: auto !important;
        max-width: unset !important;
        font-size: $buttons-font-size;
        line-height: $buttons-line-height;
        border-radius: $buttons-radius;
        height: $buttons-height !important;
        min-height: $buttons-height !important;
        max-height: $buttons-height !important;
        padding: 0 45px !important;
        font-weight: bold;
        text-transform: uppercase;
        background: $form-submit-button-background;
        color: $form-submit-button-color;
      }

      &:hover {
        .loading-spinner-container {
          .loading-spinner-wrapper div > div {
            background: $form-submit-button-color-hover;
          }
        }

        button {
          background: $form-submit-button-background-hover;
          color: $form-submit-button-color-hover;
        }

      }
    }
  }


  .agency-informations, .rooming-block, .placement-block, .order-block, .centralizer-block {
    .registration-block-header {
      h2 {
        @media only screen and (max-width: $screen-xs-max) {
          padding: 15px 20px;
        }
      }
    }
  }
}
