.c-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  width: auto;
  height: $buttons-height;
  min-height: $buttons-height;
  max-height: $buttons-height;
  border: $buttons-border;
  text-decoration: none;
  text-transform: uppercase;
  padding: $buttons-padding;
  font-size: $buttons-font-size;
  line-height: $buttons-line-height;
  font-family: $buttons-family;
  cursor: pointer;
  box-shadow: $buttons-shadow;
  transition-duration: 200ms;
  border-radius: $buttons-radius;
  font-weight: bold;

  &-primary {
    color: $white !important;
    background: $grey;

    &:hover {
      background: $black;
      transition-duration: 200ms;
    }
  }

  &-secondary {
    color: $white !important;
    background: $third-color;
    border: 2px solid $third-color;

    &:hover {
      background: $red;
      color: $white !important;
      transition-duration: 200ms;
    }

    &.inscription-btn-header {
      color: $white !important;
      background: $dark-blue;
      border: none;

      &:hover {
        background: $black;
        transition-duration: 200ms;
      }
    }
  }

  &.bigger {
    width: 168px;
    height: 40px;
  }

  &.with-shadow {
    box-shadow: 0 3px 7px rgba(0, 0, 0, .2);
  }
}
