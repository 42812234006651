.loading-spinner {
  &-container {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 52%;
    transform: translateY(-50%);
    z-index: 1;
    display: inline-block;
    overflow: hidden;
    background: none;
  }

  &-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
    div {
      box-sizing: content-box;

      > div {
        position: absolute;
        width: 3px;
        height: 3px;
        background: $btn-loading-background;
        border-radius: 50%;
        animation: loading-spinner-wrapper 1s linear infinite;
      }

      &:nth-child(1) > div {
        left: 15px;
        top: 8px;
        animation-delay: -0.8888888888888888s;
      }

      &:nth-child(2) > div {
        left: 13px;
        top: 12px;
        animation-delay: -0.7777777777777778s;
      }

      &:nth-child(3) > div {
        left: 9px;
        top: 15px;
        animation-delay: -0.6666666666666666s;
      }

      &:nth-child(4) > div {
        left: 5px;
        top: 14px;
        animation-delay: -0.5555555555555556s;
      }

      &:nth-child(5) > div {
        left: 4px;
        top: 11px;
        animation-delay: -0.4444444444444444s;
      }

      &:nth-child(6) > div {
        left: 4px;
        top: 5px;
        animation-delay: -0.3333333333333333s;
      }

      &:nth-child(7) > div {
        left: 5.5px;
        top: 3px;
        animation-delay: -0.2222222222222222s;
      }

      &:nth-child(8) > div {
        left: 10px;
        top: 1px;
        animation-delay: -0.1111111111111111s;
      }

      &:nth-child(9) > div {
        left: 13px;
        top: 4px;
        animation-delay: 0s;
      }
    }

    & > div {
      &:nth-child(1) {
        transform: rotate(0deg);
        transform-origin: 16px 10px;
      }

      &:nth-child(2) {
        transform: rotate(40deg);
        transform-origin: 14px 14px;
      }

      &:nth-child(3) {
        transform: rotate(80deg);
        transform-origin: 11px 16px;
      }

      &:nth-child(4) {
        transform: rotate(120deg);
        transform-origin: 6px 15px;
      }

      &:nth-child(5) {
        transform: rotate(160deg);
        transform-origin: 4px 12px;
      }

      &:nth-child(6) {
        transform: rotate(200deg);
        transform-origin: 4px 7px;
      }

      &:nth-child(7) {
        transform: rotate(240deg);
        transform-origin: 6px 4px;
      }

      &:nth-child(8) {
        transform: rotate(280deg);
        transform-origin: 11px 3px;
      }

      &:nth-child(9) {
        transform: rotate(320deg);
        transform-origin: 14px 5px;
      }
    }
  }
}

// LOADING SPINNER
@keyframes loading-spinner-wrapper {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.34, 1.34);
  }
  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
