.footer {
  background: $footer-background-color;

  &-logo-container {
    padding: 0 50px;
    @media (max-width: $screen-sm-max) {
      padding: 0 20px;
    }
  }

  &-menu-container {
    position: relative;
    background: $footer-background-color;
    padding: 0 50px;
    @media (max-width: $screen-sm-max) {
      padding: 0 20px;
    }

    .footer-menu {
      padding: 0 0 40px 0;
      @media (max-width: $screen-xs-max) {
        padding: 20px 0 40px;
      }
    }
  }

  &-wrapper {
    position: relative;
    padding: 30px 0;
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;

    &.in-line {
      flex-direction: row;
      justify-content: center;
      padding: 50px 0;
      @media (max-width: $screen-md-max) {
        .footer-menu {
          display: flex;
          justify-content: flex-start;
        }
      }
      @media (max-width: $screen-sm-max) {
        flex-direction: column-reverse;
        align-items: center;
        padding: 30px 0;
        .footer {
          &-brand {
            position: relative;
            right: unset;
            top: unset;
            transform: unset;
            padding-bottom: 20px;
          }

          &-menu {
            justify-content: center;

          }
        }
      }
    }

    &.in-column {
      flex-direction: column;
      align-items: center;

      .footer {
        &-brand {
          position: relative;
          right: unset;
          top: unset;
          transform: unset;
          left: unset;
          padding-bottom: 20px;
        }

        &-menu {
          display: flex;
          justify-content: center;
        }
      }
    }


  }

  &.no-links-hyphen {
    .footer-menu ul li {
      a {
        color: $white;
        padding-right: 50px;
        @media (max-width: $screen-xs-max) {
          padding-right: 0;
        }

        &:after {
          display: none;
        }

        &:hover {
          color: $gold !important;
        }
      }
    }
  }

  &-logo {
    display: flex;
    justify-content: center;
    padding: 30px 0 40px;
    @media (max-width: $screen-xs-max) {
      padding: 30px 0 20px;
    }
  }

  &-menu {
    width: 100%;

    ul {
      @media (max-width: $screen-xs-max) {
        flex-direction: column;
      }

      li {
        &:first-child {
          a {
            padding-right: 68px !important;
            @media (max-width: $screen-xs-max) {
              padding: 0 0 15px !important;

            }
          }
        }

        &:last-child {
          a {
            padding-right: 0 !important;
            @media (max-width: $screen-xs-max) {
              padding: 0 !important;
            }
          }
        }

        a {
          font-family: $footer-link-font-family;
          padding-right: 15px;
          position: relative;
          color: $footer-link-color;
          font-size: $footer-font-size;
          line-height: 18px;
          font-weight: 400;


          &:hover {
            color: $footer-link-color-hover;
          }

          @media (max-width: $screen-xs-max) {
            padding: 0 0 15px 0;
            display: block;
          }

          &:after {
            content: '-';
            color: $footer-link-color;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 14px;
            @media (max-width: $screen-xs-max) {
              display: none;
            }
          }
        }
      }
    }
  }

  .footer-brand {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 200px;
    }

    img {
      width: 100px;
    }
  }

}
