////****    BASE    ****////
body.login,
.login .layout-container,
html {
  @media only screen and (max-width: $screen-xs-max) {
    height: 100% !important;
    min-height: 100% !important;
  }
}

.login {

  &.home-login {
    .return-home-container {
      display: none !important;
    }
  }

  .layout-container {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: $screen-xs-max) {
      justify-content: flex-start;
    }

  }

  main {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 50px 0;
    flex-grow: 1;
    flex-direction: column;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width: $screen-xs-max) {
      padding: 0;
      justify-content: flex-start;
    }
  }

  .header-logo.outside-box {
    display: none;
    justify-content: center;
    margin: $login-logo-margin-top;
  }

  &-box {
    background: $login-box-background;
    width: 100%;
    max-width: $login-box-width;
    border-radius: $login-box-radius;
    @media only screen and (max-width: $screen-xs-max) {
      width: 100%;
      border-radius: 0 !important;
      max-width: unset;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    .header-logo.inside-box {
      display: none;
      justify-content: center;
      margin: $login-logo-margin-top;
    }

    .first-connexion {
      display: none;
      text-align: center;
      max-width: $login-box-form-width;
      margin: $login-first-connexion-margin;
      padding: $login-box-form-padding;
      padding-top: 0;
      padding-bottom: 0;
      @media only screen and (max-width: $screen-xs-max) {
        max-width: 100%;
        width: 100%;
      }

      a {
        width: $login-buttons-width;
      }

      p {
        padding-top: 10px;
        font-size: $login-first-connexion-text-size;
        line-height: $login-first-connexion-text-line-height;
        color: $form-components-color;
      }

    }

    .already-connected {
      padding-bottom: 20px;

      p {
        text-align: center;
        color: $form-components-color;
        font-size: $login-already-connected-text-size;
        line-height: $login-already-connected-text-line-height;
      }

      a {
        font-size: $login-already-connected-text-size;
        line-height: $login-already-connected-text-line-height;
        color: $login-already-connected-link-color;
        display: block;

        &:hover {
          color: $login-already-connected-link-color-hover;
        }
      }
    }

    .block-title {
      background: $login-title-without-bandeau-background !important;

      @media screen and (max-width: $screen-xs-max) {
        position: relative;
        top: unset;
      }

      h1 {
        color: $login-title-without-bandeau-color;
        border-top-left-radius: $login-box-radius;
        border-top-right-radius: $login-box-radius;
        @media only screen and (max-width: $screen-xs-max) {
          border-radius: 0 !important;
        }

        &.no-letter-spacing {
          letter-spacing: 0;
        }
      }
    }

    .user-login-form {
      //max-height: calc(100svh - 56px);
      //height: calc(100dvh);
      max-width: $login-box-form-width;
      margin: 0 auto;
      padding: $login-box-form-padding;
      @media only screen and (max-width: $screen-xs-max) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-width: 100%;
      }

      .form-type-checkbox {
        > label {
          font-size: $form-checkbox-label-font-size;
          line-height: $form-checkbox-label-line-height;
        }
      }

    }

    .submit-container {
      margin-top: 40px;
      @media only screen and (max-width: $screen-xs-max) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    .submit-wrapper {
      position: relative;

      button {
        width: $login-buttons-width;
      }
    }

    .legal-notice, .forgot-password {
      margin-top: 25px;
      display: none;
      justify-content: center;

      a {
        font-size: $login-legal-notice-link-size;
      }
    }


  }

  &.form-error {
    .submit-wrapper {
      margin-top: 10px;
    }
  }

  ////****    VARIANTS    ****////

  &.with-header-logo {
    .header-logo {
      display: flex;
    }
  }

  &.with-logo-outside-box {
    .login-box .header-logo.inside-box {
      display: none;
    }

    &.reset-pwd {
      .login-box .block-title {
        border-top-right-radius: $login-box-radius;
        border-top-left-radius: $login-box-radius;
      }
    }
  }

  &:not(.with-logo-outside-box) {
    .header-logo.outside-box {
      display: none;
    }
  }

  &.with-title-bandeau {
    .login-box .block-title h1 {
      background: $login-title-with-bandeau-background;
      color: $login-title-with-bandeau-color;
    }
  }

  &.with-first-connexion {
    .login-box {
      .first-connexion {
        display: block;
      }

      .forgot-password {
        display: flex;
      }

      .legal-notice {
        margin-top: 15px;
      }
    }
  }

  &.with-legal-notice {
    .legal-notice {
      display: flex;
    }
  }

  &:not(.with-header-logo):not(.with-first-connexion) {
    .block-title {
      border-top-right-radius: $login-box-radius;
      border-top-left-radius: $login-box-radius;
    }
  }

  &.reset-pwd, &.check-email, &.reinit-pwd {

    .form-wrapper {
      @media only screen and (max-width: $screen-xs-max) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }

    .return-home-container {
      text-align: left;
      padding-bottom: 10px;
      width: 100%;
      max-width: $login-box-width;

      @media only screen and (max-width: $screen-xs-max) {
        background: $login-box-background;
        padding: 20px !important;
      }

      a {
        color: $form-components-color;
        text-decoration: underline;
      }
    }

    .user-login-form {
      max-width: $login-box-form-width;
      margin: 0 auto;
      @media only screen and (max-width: $screen-xs-max) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        form {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }
        .legal-notice {
          align-items: flex-end;
        }
      }


      .reset-user-intro {
        padding: 0 20px;
      }

      form {
        padding: 20px 20px 0;

        .form-item {
          margin-bottom: 0;
        }
      }
    }


  }

  &.check-email {
    .legal-notice {
      flex-grow: 1;
    }
  }

  &.reinit-pwd {

    form {
      > div, > div .form-item, > div .form-item > div {
        @media only screen and (max-width: $screen-xs-max) {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }
      }
    }

    .form-item {

      position: relative;

      &.first-pwd, &.second-pwd {
        margin-bottom: 20px !important;
      }

      &.second-pwd {
        margin-bottom: 10px !important;

        input {
          margin-bottom: 40px !important;

        }

        .field-errors-list {
          li {
            text-align: center;
          }
        }
      }

      .help-text {
        line-height: 13px;
        padding-top: 6px;
        color: $form-components-color;
      }
    }

    .show-pwd {
      position: absolute;
      right: 10px;
      top: 21px;
      cursor: pointer;

      svg {
        path {
          stroke: $show-pwd-color;
        }
      }

      &:hover {
        svg {
          path {
            stroke: $show-pwd-color-hover;
          }
        }
      }

      svg {
        width: 15px;
        height: auto;
      }

      .show-pwd-icon {
        padding-top: 11px;
      }

      .hide-pwd-icon {
        padding-top: 9Px;
      }

      &.active {
        .show-pwd-icon {
          display: block;
        }

        .hide-pwd-icon {
          display: none;
        }
      }
    }

    .submit-container {
      margin-top: 0;
    }

    .submit-wrapper {
      display: flex;
      flex-direction: column;
    }

  }

}
