// ----- ACTIVITE ----- //

//.container-wrapper   // TODO A VOIR
$activity-wrapper-padding-right: 50px;
$activity-wrapper-padding-left: 50px;
//xs
$activity-wrapper-padding-right-xs: 20px;
$activity-wrapper-padding-left-xs: 20px;
// END TODO A VOIR


//.activity
$activity-bandeau-background-color: $gold !important;
$activity-bandeau-text-color: $black !important;

//&-intro
$activity-intro-margin-bottom: 80px;
$activity-intro-margin-bottom-xs: 50px;
//p
$activity-intro-p-font-family: $font-family-primary;
$activity-intro-p-font-weight: $font-weight-regular;
$activity-intro-p-text-align: center;
$activity-intro-p-font-size: 16px;
$activity-intro-p-line-height: 19px;
$activity-intro-p-letter-spacing: 0.5px;
$activity-intro-text-color: $secondary-color !important;
$activity-intro-text-padding-xs: 0 15px !important;

//&-content // can add ' .with-background '
$activity-content-padding: 75px 0 130px;
$activity-content-max-width: 1264px;
$activity-content-padding-xs: 55px 0 60px;

//&-wrapper
$activity-content-wrapper-padding: 0 40px;
$activity-content-wrapper-padding-sm: 0 30px;
$activity-content-wrapper-padding-xs: 0 20px;

//&.two-lines && &.in-mosaic
//.activity-list // can add ' .item-25 ' -- ' .item-33 ' -- ' .item-50 ' at '.activity-list'

//&-title
$activity-both-min-height: 84px;
//p
$activity-both-title-padding: 15px;
$activity-both-title-font-size: 21px;
$activity-both-title-font-family: $font-family-primary;
$activity-both-title-line-height: 27px;
$activity-both-title-letter-spacing: 1px;
$activity-both-title-font-weight: $font-weight-bold;

//&-img
$activity-both-title-min-height: 294px;
$activity-both-title-max-height: $activity-both-title-min-height;

//&-text
$activity-both-text-min-height: 95px;
$activity-both-text-background: $black;
$activity-both-text-padding: 20px;
$activity-both-text-min-height: 95px;
//p
$activity-both-text-p-color: $white;


// ********' .two-lines '********//


// -- two-lines -- //
//--item-33
//--item-25
//--item-50

// -- in-lines-slider -- //
// -- in-lines -- //
// -- ' .with-slider ' -- //


// ********' .in-mozaic '********//
//.activity-list
$activity-in-mosaic-max-height: 950px;

//&-column
$activity-in-mosaic-padding-right: 10px; // (gap on each column)
$activity-in-mosaic-padding-left: $activity-in-mosaic-padding-right; // (gap on each column)
//sm
$activity-in-mosaic-padding-right-sm: 5px;
$activity-in-mosaic-padding-left-sm: $activity-in-mosaic-padding-right-sm;

//:last-child
$activity-in-mosaic-margin-bottom-sm: 30px;

$activity-in-mosaic-margin-bottom: 50px; // (gap in each column)
$activity-in-mosaic-margin-bottom-md: 30px; // (gap in each column)
