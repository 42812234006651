.agency-informations {
  border-radius: $form-block-radius;

  .registration-block-header {
    border-top-left-radius: $form-block-radius;
    border-top-right-radius: $form-block-radius;
  }

  .registration-block-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-item-isParticipating {
      > label {
        padding-bottom: 0;

        @media only screen and (max-width: $screen-sm-min) {
          flex-direction: row;
          text-align: center;
        }
      }

      @media only screen and (min-width: $screen-sm-min) {
        flex-direction: row;
        justify-content: center;

        #registration_form_isParticipating {
          margin-left: 20px;
        }
      }

    }

    .form-item-choice-container {
      justify-content: center;
      @media only screen and (max-width: $screen-xs-max) {
        padding-top: 20px;
      }
    }

    .agency-form-section {
      width: 100%;
      margin-bottom: 20px;

      .registration-block-sub-title {
        p {
          text-align: center;
          @media only screen and (max-width: $screen-xs-max) {
            text-align: left;
          }
        }
      }

      .form-item-agency {
        padding-top: 20px;
        max-width: $form-block-agency-infos-max-width;
        margin: 0 auto;

        select {
          max-width: 400px;
          margin: 0 auto;
          width: 100%;
          @media only screen and (max-width: $screen-xs-max) {
            max-width: unset;
          }
        }

        .form-item-type {
          .form-item-choice-container {
            padding-top: 6px;
            justify-content: flex-start;
          }
        }

        .field-errors-list {
          text-align: center;
        }
      }

      .absent-participant-form-section {
        max-width: $form-block-agency-infos-max-width;
        margin: 20px auto 0;

        > .form-item {
          margin-bottom: 0;
          padding-top: 20px;
        }
      }
    }

    .absent-participant-form-section {
      max-width: $form-block-agency-infos-max-width;
      width: 100%;
      margin: 20px auto 0;

      > .form-item {
        margin-bottom: 0;
        padding-top: 20px;
      }

      .registration-block-sub-title {
        p {
          text-align: center;
        }
      }
    }
  }
}
