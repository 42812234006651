.hero {
  position: relative;
  background: $hero-background-color;

  //-- GLOBAL --//

  .decoration-1, .decoration-2 {
    display: none;
    position: absolute;
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  .decoration-1 {
    left: 0;
    max-width: 427px;
    bottom: 110px;
    @media only screen and (max-width: 1300px) {
      max-width: 370px;
      bottom: 130px;
    }

    @media only screen and (max-width: 1120px) {
      max-width: 270px;
      bottom: 158px;
    }

    @media only screen and (max-width: 880px) {
      max-width: 210px;
      bottom: 175px;
    }
    @media only screen and (max-width: $screen-xs-max) {
      bottom: 103px;
    }

    @media only screen and (max-width: 640px) {
      max-width: 170px;
      bottom: 114px;
    }
    @media only screen and (max-width: 540px) {
      max-width: 120px;
      bottom: 128px;
    }
    @media only screen and (max-width: 450px) {
      max-width: 80px;
      bottom: 133px;
    }
    @media only screen and (max-width: 365px) {
      max-width: 75px;
      bottom: 135px;
    }
  }

  .decoration-2 {
    right: 50px;
    max-width: 250px;
    bottom: 70px;

    @media only screen and (max-width: 1020px) {
      max-width: 210px;
      bottom: 90px;
    }

    @media only screen and (max-width: 840px) {
      max-width: 180px;
      bottom: 110px;
      right: 20px;
    }

    @media only screen and (max-width: $screen-xs-max) {
      bottom: 50px;
      max-width: 130px;
    }
    @media only screen and (max-width: 540px) {
      max-width: 110px;
      margin-right: 0;
    }

    @media only screen and (max-width: 470px) {
      max-width: 90px;
      margin-right: -15px;
      bottom: 70px;
    }
    @media only screen and (max-width: 400px) {
      max-width: 70px;
    }
  }

  &-container {
    display: flex;
    margin: 0 auto;

    &-left, &-right {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      @media only screen and (max-width: $screen-sm-max) {
        width: 100%;
        align-items: center;
      }
    }
  }

  &-logo {
    display: none;
    padding-bottom: 30px;
    @media only screen and (max-width: $screen-sm-max) {
      display: flex;
      justify-content: center;
    }

    @media only screen and (max-width: $screen-xs-max) {
      padding-bottom: 20px;
    }

    svg {
      display: block;
      width: 100%;
      height: auto;
      @media only screen and (max-width: $screen-xs-max) {
        height: auto;
        width: 100px;
      }

    }
  }

  &-title {

    h2 {
      font-weight: $font-weight-regular;
      font-size: $hero-title-font-size;
      line-height: $hero-title-line-height;
      color: $hero-title-color;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 0;
      @media only screen and (max-width: $screen-md-max) {
        font-size: $hero-title-font-size-lg;
        line-height: $hero-title-line-height-lg;
        text-align: center;
      }
      @media only screen and (max-width: $screen-xs-max) {
        font-size: $hero-title-font-size-xs;
        line-height: $hero-title-line-height-xs;
      }

      span {
        display: block;
      }
    }
  }

  &-sub-title {
    display: none;
    margin-top: 30px;
    @media only screen and (max-width: $screen-xs-max) {
      padding-bottom: 20px;
    }

    p {
      margin: 0 auto;
      font-size: $hero-subtitle-font-size;
      line-height: $hero-subtitle-line-height;
      color: $hero-subtitle-color;
      font-weight: 500;
      text-transform: initial;
      text-shadow: 0 1px 2px rgb(0, 0, 0, 0.4);
      @media only screen and (max-width: $screen-sm-max) {
        text-align: center;
      }
      @media only screen and (max-width: $screen-xs-max) {
        font-size: $hero-subtitle-font-size-xs;
        line-height: $hero-subtitle-line-height-xs;
      }

      span {
        display: block;
      }
    }
  }

  &-cta {
    display: none;
    margin-top: 40px;
    @media only screen and (max-width: $screen-sm-max) {
      margin-top: 20px;
    }
    @media only screen and (max-width: $screen-xs-max) {
      margin-top: 0;
    }
  }

  &-svg {
    @media only screen and (max-width: $screen-xs-max) {
      width: 100%;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  &-images {
    display: none;
  }

  //-- IN LINE --//

  &.in-line {

    .hero {
      &-container {
        max-width: $hero-in-line-max-width;
        padding: $hero-in-line-padding-desktop;

        @media only screen and (max-width: $screen-sm-max) {
          flex-direction: column-reverse;
          padding: $hero-in-line-padding-tablet;
        }
        @media only screen and (max-width: $screen-xs-max) {
          padding: $hero-in-line-padding-mobile;
        }


        &-left {
          padding-right: 25px;
          align-items: start;
          @media only screen and (max-width: $screen-sm-max) {
            padding-right: 0;
            align-items: center;
          }
        }

        &-right {
          padding-left: 25px;
          @media only screen and (max-width: $screen-sm-max) {
            padding-left: 0;
            padding-bottom: 30px;
          }
        }
      }

      &-logo {
        @media only screen and (max-width: $screen-xs-max) {
          display: flex;
          justify-content: center;
          padding-bottom: 20px;
        }
      }

      &-date {
        text-align: left;
      }

      &-svg {
        display: flex;
        justify-content: center;
      }
    }
  }

  //-- IN COLUMN --//

  &.in-column {

    .hero {
      &-container {
        max-width: $hero-in-column-max-width;
        padding: $hero-in-column-padding-desktop;
        flex-direction: column-reverse;
        align-items: center;
        @media only screen and (max-width: $screen-sm-max) {
          padding: 50px 0 90px;
        }

        &-left, &-right {
          width: 100%;
          align-items: center;

          > * {
            text-align: center;
          }
        }
      }

      &-svg, &-logo {
        justify-content: center;

        svg {
          max-width: 500px;
        }
      }

      &-svg {
        display: flex;
      }

      &-title {
        h2 {
          text-align: center;
        }
      }

      &-date {
        text-align: center;
      }
    }
  }

  //-- IMG ONLY --//

  &.img-only {
    position: relative;
    padding: 0;
    background: none;

    .hero-title, .hero-container-right .hero-svg, .hero-container-left, .decoration-1, .decoration-2 {
      display: none;
    }

    .hero-container {
      display: block;
      margin: unset;

      &-right {
        width: 100%;
      }
    }

    .hero-images {
      display: block;
    }


    img {
      width: 100%;
      height: 100%;
      display: flex;
    }

    .hidden-desktop {
      display: none;
      width: 100%;
      @media (max-width: $screen-xs-max) {
        display: flex;
      }
    }

    .hidden-mobile {
      width: 100%;
      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }
  }

  //-- OPTIONS --//

  &.with-decoration {
    .decoration-1, .decoration-2 {
      display: block !important;
    }
  }

  &.with-cta {
    .hero-cta {
      display: block !important;
    }
  }

  &.with-sub-title {
    .hero-sub-title {
      display: block !important;
    }
  }

  &.with-logo {
    .hero-logo {
      display: flex !important;
    }
  }
}
