// ---- DATE LIMITE -----//

//-- IN BUTTON --//
$in-button-background-color: $red;
$in-button-background-color-hover: transparent;
$in-button-color-text: $white;
$in-button-color-text-hover: $red;

//-- with-shadow --/
$in-button-box-shadow: 0 4px 8px lightgrey;


//-- IN BANNER --//
$in-banner-background-color: $black;
$in-banner-color-text: $white;
