.modal {
  &-container {
    margin-top: 10px;
  }

  &-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-img {
      img {
        width: 100%;
        height: auto;
      }

    }

    &-title {
      p {
        font-family: $font-family-secondary;

        text-align: center;

        &.title {
          font-size: 17px;
          line-height: 20px;
          color: $black;
          font-weight: $font-weight-semi-bold;
          text-transform: uppercase;
          padding: 0 0 7px;
        }

        &.text {
          font-weight: 400;
          padding-bottom: 10px;
          @media (max-width: $screen-xs-max) {
            padding-bottom: 20px;
          }
        }
      }
    }

    &-cta {
      button {
        text-transform: initial;
        font-size: 14px;
        margin: 0 auto;
      }
    }
  }

  &-popin {
    display: none;
    flex-direction: column;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background: $white !important;
    max-width: 810px;
    max-height: 95vh;
    width: 100%;
    height: auto;
    z-index: 502;
    padding: 60px 25px;

    @media (max-width: $screen-md-max) {
      max-width: 900px;
      max-height: 80vh;
    }
    @media (max-width: $screen-sm-max) {
      max-width: 650px;
    }
    @media (max-width: $screen-xs-max) {
      top: 0;
      left: 0;
      transform: unset;
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      padding: 60px 10px;
    }

    &.meantime_updated {
      .modal-popin-header {
        border-bottom: 1px solid $red !important;

        h4 {
          color: $red !important;
        }
      }
    }

    &-title {
      h2 {
        color: $modal-title-color;
        background: $hebergement-hotel-name-background;
        text-transform: uppercase;
        letter-spacing: 0;
      }
    }

    &-close {
      position: absolute;
      right: 10px;
      top: 30px;
      width: 50px;
      height: 50px;
      z-index: 505;
      cursor: pointer;
      @media (max-width: $screen-xs-max) {
        top: 20px;
        width: 30px;
        height: 30px;
      }
    }

    &-container, &-title, &-sub-title {
      padding: 0 0;
      @media (max-width: $screen-xs-max) {
        padding: 0;
      }
    }

    &-title {
      h3 {
        font-family: $font-family-secondary;
        text-transform: uppercase;
        font-size: 26px;
        line-height: 30px;
        font-weight: $font-weight-semi-bold;
      }
    }

    &-container {
      overflow: auto;
    }

    &-sub-title {
      padding-bottom: 10px;

      p {
        font-weight: normal;
        font-size: 16px;
        @media (max-width: $screen-xs-max) {
          font-size: 14px;
        }
      }
    }

    ul {
      padding-left: 30px;
      @media (max-width: $screen-xs-max) {
        padding-left: 10px;
      }

      li {
        font-family: $font-family-arial;
        font-size: 14px;
        line-height: 16px;
        color: $primary-color;
        text-align: left;
      }
    }

    &-text {
      display: flex;
      padding-top: 7px;
      text-align: left;
      justify-content: space-between;
      @media (max-width: $screen-xs-max) {
        max-width: 100%;
      }

      .modal-popin-text-block {
        padding-top: 30px;

        &-title {
          padding-bottom: 5Px;

          p {
            font-family: $font-family-secondary;
            font-size: 16px;
            line-height: 22Px;
            font-weight: 400;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            display: inline-block;
            background: $black;
            color: $white;
            padding: 5Px;
          }
        }

        &-descr {
          padding-top: 8px;

          p, ul li {
            font-size: 14px;
            line-height: 18px;
            color: $grey;
            font-weight: 400;
          }

          ul {
            margin: 0;
            padding-left: 0;

            li {
              padding-bottom: 12px;
              padding-left: 13px;
              position: relative;

              span {
                font-size: 18px;
                position: absolute;
                left: 0;
                top: 0;
              }

              &:last-child {
                padding-bottom: 0;
              }
            }
          }


        }
      }

      &-left {
        width: 61%;
        padding-right: 40px;
        @media only screen and (max-width: $screen-sm-max) {
          width: 53%;
        }
        @media only screen and (max-width: $screen-xs-max) {
          width: 100%;
          padding-right: 0;
        }
      }

      &-right {
        width: 37%;
        @media only screen and (max-width: $screen-sm-max) {
          width: 47%;
        }
        @media only screen and (max-width: $screen-xs-max) {
          width: 100%;
        }
      }

      .field__item {
        display: flex;
        @media (max-width: $screen-xs-max) {
          flex-direction: column;
        }

        p {
          font-size: 12px;
          font-weight: $font-weight-normal;
          width: 50%;
          @media (max-width: $screen-xs-max) {
            width: 100%;
            padding: 0 !important;
          }

          &:first-child {
            padding-right: 15px;
          }

          &:last-child {
            padding-left: 15px;
          }

          span {
            display: block;
            padding-top: 20px;

            &.italic {
              display: inline-block;
              padding: 0;
              font-weight: $font-weight-normal;
            }
          }

          em {
            font-weight: $font-weight-normal;
          }
        }
      }

      a {
        padding-top: 20px;
        display: block;
        color: $primary-color;
        font-family: $font-family-primary;
        font-weight: $font-weight-normal;
        font-size: 12px;
        line-height: 16px;
        text-decoration: underline;
      }
    }

    &-img-list {
      padding-top: 60px;

      img {
        width: 100%;
        height: auto;
      }
    }

    &.modal-avoir {
      height: auto !important;
      max-width: 950px !important;
      border-radius: 40px;
      background: $blue;
      padding: 40px 25px 20px 25px;
      @media only screen and (max-width: $screen-md-max) {
        max-width: calc(100% - 60px) !important;
      }
      @media only screen and (max-width: $screen-sm-max) {
        padding: 70px 25px 20px 25px;
      }
      @media only screen and (max-width: $screen-xs-max) {
        max-width: calc(100% - 40px) !important;
        left: 20px;
        transform: translateY(-50%);
        top: 50%;
        padding: 70px 20px 50px 20px;
        border-radius: 20px;
      }

      .close-button {
        position: absolute;
        top: 20px;
        right: 20px !important;
        background: $primary-color;
        border-radius: 100%;
        border: none;
        padding: 10px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @media only screen and (max-width: $screen-sm-max) {
          right: 0;
          transform: unset;
          width: 40px;
          height: 40px;
        }
        @media only screen and (max-width: $screen-xs-max) {
          top: 20px;
          right: 20px !important;
          width: 35px;
          height: 35px;
        }

        span {
          color: $white;
          @media only screen and (max-width: $screen-sm-max) {
            font-size: 20px;
          }
          @media only screen and (max-width: $screen-xs-max) {
            font-size: 16px;
          }

          &:before {
            font-size: 24px;
            display: flex;
            @media only screen and (max-width: $screen-sm-max) {
              font-size: 20px;
            }
            @media only screen and (max-width: $screen-xs-max) {
              font-size: 16px;
            }
          }
        }
      }

      .modal-popin {
        &-container {
          overflow: hidden;
          padding: 0 50px;
          @media only screen and (max-width: $screen-md-max) {
            padding: 0 20px;
          }
          @media only screen and (max-width: $screen-xs-max) {
            padding: 0;
          }
        }

        &-text {
          padding-top: 0;
          width: 100%;
          max-width: 100%;

          p {
            color: $white;
            text-align: center;
            font-size: 20px;
            line-height: 24px;
            padding-bottom: 20px;
            font-weight: normal;
            @media only screen and (max-width: $screen-xs-max) {
              font-size: 16px;
              line-height: 18px;
            }

            &:first-child {
              padding: 0 70px 20px;
              @media only screen and (max-width: $screen-sm-max) {
                padding: 0 0 20px;
              }
            }

            &.bold {
              font-weight: bold;
              padding: 0 120px;

              @media only screen and (max-width: $screen-sm-max) {
                padding: 0;
              }
            }

            span.old {
              font-weight: bold;
            }

            &:last-child {
              padding-top: 20px;
            }
          }
        }
      }

    }

    &.with-fixed-header {
      border-radius: 10px;
      overflow: auto;
      max-width: 870px;
      padding: 35px 48px;
      @media only screen and (max-width: $screen-sm-max) {
        max-width: 670px;
        padding: 35px;
      }
      @media (max-width: $screen-xs-max) {
        padding: 20px;
        border-radius: 0;
      }

      &.hotel {
        .modal-popin-container .modal-popin-img-list .img-container img.margin-bottom {
          margin-bottom: 1px;
        }
      }

      &.plan-de-table {
        .modal-popin-img-list {
          padding-top: 20px;
        }
      }

      &.no-border {
        .modal-popin-header {
          border: none;
        }
      }


      &.meru {
        h4, h3, .modal-popin-text-block-title p {
          color: $black !important;
        }

        .modal-popin-text-block-title {
          border-bottom: 1px solid $black;
        }

        .modal-popin-website {
          &:hover {
            a {
              color: $black;
            }

            svg path {
              fill: $black;
            }
          }
        }
      }

      &.bivouac {
        h4, h3, .modal-popin-text-block-title p {
          color: $blue !important;
        }

        h3 {
          max-width: 576px;
          letter-spacing: 0;
        }

        .modal-popin-text-block-title {
          border-bottom: 1px solid $blue;
        }

        .modal-popin-website {
          &:hover {
            a {
              color: $blue;
            }

            svg path {
              fill: $blue;
            }
          }
        }
      }

      &.lodge {
        h4, h3, .modal-popin-text-block-title p {
          color: $black !important;
        }

        .modal-popin-text-block-title {
          border-bottom: 1px solid $black;
        }

        .modal-popin-website {
          &:hover {
            a {
              color: $black;
            }

            svg path {
              fill: $black;
            }
          }
        }
      }

      &.tui-blue {
        h4, h3, .modal-popin-text-block-title p {
          color: $black !important;
        }

        .modal-popin-text-block-title {
          border-bottom: 1px solid $black;
        }

        .modal-popin-website {
          &:hover {
            a {
              color: $black;
            }

            svg path {
              fill: $black;
            }
          }
        }
      }

      .modal-popin {

        &-header {
          padding-bottom: 10px;
          border-bottom: 1px solid $modal-header-border-color;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &-fixed-title {
          align-self: flex-end;

          h3 {
            color: $modal-header-text-color;
            letter-spacing: 0.5px;
          }
        }


        &-close {
          position: relative;
          right: unset;
          top: unset;
          width: 50px;
          height: 50px;
          z-index: 505;
          cursor: pointer;
          margin-top: -50px;
          margin-right: -15px;

          svg {
            width: 100%;
            height: auto;
            margin-top: 24px;
          }

          &:hover {
            svg path {
              stroke: $dark-blue;
            }
          }
        }

        &-content {
          padding-top: 20px;

          .modal-popin-website {
            padding-top: 10px;
            display: flex;
            align-items: center;
            @media only screen and (max-width: $screen-xs-max) {
              flex-flow: row wrap;
            }

            .text {
              padding-right: 24px;
              @media only screen and (max-width: $screen-xs-max) {
                width: 100%;
                padding-right: 0;
                padding-bottom: 10Px;
                p {
                  max-width: 70%;
                }
              }

              p {
                font-weight: bold;
                text-transform: uppercase;
              }
            }

            svg {
              margin-right: 5px;
            }

            a {
              font-style: italic;
              color: $grey;
              text-decoration: underline;
            }
          }
        }

        &-sub-title {
          padding: 10px 0 0;
          display: flex;
          align-items: center;

          p {
            position: relative;
            font-size: 14px;

            svg {
              margin-left: 10px;
              position: absolute;
              top: 4px;
              left: 0;
            }

            a {
              margin-left: 30px;
              color: $primary-color;
              font-size: 14px;
              padding-left: 30px;
              position: relative;
              @media (max-width: $screen-xs-max) {
                display: block;
                margin: 10px 0 30px;
                svg {
                  margin-left: 0;
                }
              }

              &:hover {
                color: $blue;
              }
            }
          }
        }

        &-container {
          padding: 10px 0 0;

          &.with-accord {
            .modal-text.size-100.accord {
              margin-bottom: 0;

              a {
                display: flex;
                justify-content: flex-end;
                padding-top: 0;
                @media (max-width: $screen-xs-max) {
                  justify-content: flex-start;
                }
              }

              &.with-contact {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;
                @media (max-width: $screen-xs-max) {
                  flex-direction: column;
                  > div {
                    width: 100%;
                  }
                }
              }
            }

            .modal-popin-img-list {
              padding-top: 20px;
            }
          }

          .modal-popin-text {
            padding-top: 0;
            max-width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            .modal-text {
              @media (max-width: $screen-sm-max) {
                padding-bottom: 30px;
              }
              @media (max-width: $screen-xs-max) {
                width: 100% !important;
              }

              &.image-right {
                padding-right: 20px;
                @media (max-width: $screen-sm-max) {
                  padding-right: 0;
                }

                &.no-margin {
                  padding-right: 0;
                }

                &.padding-bottom-10 {
                  padding-bottom: 10px;
                  @media (max-width: $screen-sm-max) {
                    padding-bottom: 30px;
                  }
                }

                &.padding-bottom-30 {
                  padding-bottom: 30px;
                }
              }

              .bold {
                font-weight: 600 !important;
              }

              .green {
                color: $third-color;
              }

              &.size-30, &.size-35 {
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                @media (max-width: $screen-sm-max) {
                  width: 35%;
                }
                @media (max-width: $screen-xs-max) {
                  align-items: flex-start;
                }

                .modal-text-content p {
                  text-align: right;
                  padding-bottom: 3px;
                  @media (max-width: $screen-xs-max) {
                    text-align: left;
                  }

                  &.two-lines {
                    strong {
                      display: block;
                    }
                  }
                }
              }

              &-title {
                margin-bottom: 10Px;

                span {
                  background: $modal-text-title-background;
                  color: $modal-text-title-color;
                  font-family: $font-family-primary;
                  font-size: 16px;
                  line-height: 21px;
                  padding: 6px 7px 4px;
                  letter-spacing: 0.2px;
                }
              }

              &-content {
                ul {
                  @media (max-width: $screen-xs-max) {
                    width: 100%;
                  }

                  li {
                    position: relative;

                    &:before {
                      content: '';
                      position: absolute;
                      width: 4px;
                      height: 4px;
                      left: -10px;
                      background: $primary-color;
                      border-radius: 100%;
                      top: 5px;
                    }

                    span {
                      display: block;
                      font-style: italic;
                      font-size: 12px;
                    }
                  }
                }
              }

              p {
                padding-bottom: 15px;
                text-align: left;

                &:last-child {
                  padding-bottom: 0;
                }

                &.sub-title {
                  font-family: $surprises-block-title-family;
                  line-height: 17px;
                  font-weight: $font-weight-normal;
                  @media (max-width: $screen-sm-max) {
                    br {
                      display: none;
                    }
                  }
                }


                &.no-padding {
                  padding: 0;
                }

                &.infos {
                  padding-bottom: 0;

                  span {
                    &:not(.italic) {
                      color: $secondary-color;
                      font-weight: $font-weight-normal;
                      font-family: $font-family-primary;
                    }

                    &.italic {
                      display: block;
                      font-size: 12px;
                    }
                  }
                }

                &.timing {
                  margin-top: 20px;
                  padding-bottom: 3px;

                  &.big-marge {
                    margin-top: 70px;
                  }
                }

                &.separator {
                  display: flex;
                  padding: 0;
                  margin-top: 50px;
                  margin-bottom: 20px;
                  @media (max-width: $screen-sm-max) {
                    margin-top: 30px;
                  }

                  &:before {
                    content: '';
                    width: 60px;
                    height: 2px;
                    background: $secondary-color;
                  }

                  &.small-marge {
                    margin-top: 20px;
                  }

                  &.medium-marge {
                    margin-top: 35px;
                  }

                  &.big-marge {
                    margin-top: 70px;
                  }
                }

                button {
                  margin-top: 10px;
                }

                &.no-padding-bottom {
                  padding-bottom: 0;
                }

                span {
                  &.medium {
                    font-weight: 500;
                  }

                }
              }
            }

          }

          .modal-popin-img-list {
            display: flex;
            flex-flow: row wrap;
            padding-top: 30px;
            @media (max-width: $screen-xs-max) {
              padding-top: 40px;
            }

            .img-container {
              padding-bottom: 8px;

              &.size-67 {
                width: 67%;
              }

              &.size-33 {
                width: 33%;
              }

              &.no-padding {
                padding-bottom: 0;
              }

              &:last-child {
                padding-bottom: 0;
              }

              @media (max-width: $screen-xs-max) {
                width: 100% !important;
                padding: 0 0 8px 0 !important;
                &:last-child {
                  padding-bottom: 15px;
                }
              }


              &.padding-right {
                padding-right: 4px
              }

              &.padding-left {
                padding-left: 4px
              }

              img {
                display: flex;

                &.margin-top {
                  margin-top: 7Px;
                }

                &.margin-bottom {
                  margin-bottom: 7px;
                }
              }
            }
          }

          &.in-line {
            display: flex;
            margin-top: 30px;
            overflow: hidden;
            @media (max-width: $screen-sm-max) {
              flex-direction: column;
              > div {
                width: 100%;
              }
            }

            &.image-left {
              .modal-popin-text {
                padding-left: 20px;
                @media (max-width: $screen-sm-max) {
                  padding-left: 0;
                }
              }

              @media (max-width: $screen-sm-max) {
                .modal-popin-img-list {
                  order: 2;
                }
                .modal-popin-text {
                  order: 1;
                }
              }
            }

            &.image-right {
              .modal-popin-text {
                padding-right: 20px;

                &.no-marge {
                  padding-right: 0;
                }

                &.marge-right-10 {
                  padding-right: 10px;
                }

                .img-container {
                  overflow: hidden;

                  img {
                    width: 100%;
                  }
                }

                &.x-big-marge {
                  padding-right: 70px;
                }

                &.big-marge {
                  padding-right: 50px;
                }

                &.mid-medium-marge {
                  padding-right: 40px;
                }

                &.medium-marge {
                  padding-right: 30px;
                }

                @media (max-width: $screen-sm-max) {
                  padding-right: 0 !important;
                }
              }
            }

            .modal-popin-img-list {
              flex-flow: column wrap;
            }
          }

          &.in-column {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            margin-top: 30px;

            .modal-popin-img-list {
              margin-top: 15px;

              &.big-margin-top {
                margin-top: 40px;
              }

              &.in-column {
                @media (max-width: $screen-sm-max) {
                  flex-direction: column;
                  > div {
                    width: 100%;
                  }
                }

                .img-container {
                  &:first-child {
                    padding-right: 4px;
                    @media (max-width: $screen-sm-max) {
                      padding-right: 0;
                    }
                  }

                  &:last-child {
                    padding-left: 4px;
                    @media (max-width: $screen-sm-max) {
                      padding-left: 0;
                    }
                  }
                }
              }
            }

            &.image-left {
              .modal-popin-text {
                @media (max-width: $screen-sm-max) {
                  flex-direction: column;
                  > div {
                    width: 100%;
                  }
                }

                .img-container img {
                  height: 100%;
                  width: 100%;
                  @media (max-width: $screen-sm-max) {
                    height: auto;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      &.activity {

        .modal-popin-header {
          h3 {
            font-family: $font-family-primary;
            font-size: 20px;
            line-height: 25px;
          }
        }

        .modal-popin-title {
          padding-bottom: 30px;

          h2 {
            font-size: 26px;
            line-height: 30px;
            @media (max-width: $screen-sm-max) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }

        .modal-popin-container .modal-popin-text {
          .modal-text {
            margin-bottom: 0;
            padding-bottom: 0;

            p {
              font-size: 16px;
              line-height: 20px;
              color: $grey;

              &.big {
                font-weight: bold !important;
                padding-top: 30px;

                &.purple {
                  color: $black;
                }

                &.green {
                  color: $black;
                }
              }
            }

          }
        }

        /*
        .modal-popin-sub-title {
          padding: 30px 0 0;

          p {
            font-size: 15px;
            line-height: 17px;
          }
        }

        .modal-popin-container .modal-popin-text .modal-text {
          margin-bottom: 0;

          p, ul li {
            margin-bottom: 10px;

            &.italic {
              margin-bottom: 0;
              padding-top: 5px;
            }

            span.italic {
              font-weight: normal;
            }
          }
        }
        */
      }

      &.hebergement {
        .modal-popin-fixed-title h3 {
          line-height: 25px;
        }

        .modal-popin {
          &-content {
            padding-top: 17px;
          }

          &-title {
            h2 {
              font-weight: 800;
              letter-spacing: 2Px;
            }
          }

          &-text {
            .modal-text-content {

              &.contact {
                p {
                  display: flex;
                  align-items: center;
                  padding-bottom: 5px;
                  color: inherit;
                  @media (max-width: $screen-xs-max) {
                    &:not(:last-child) {
                      flex-direction: column;
                      align-items: flex-start;

                      a {
                        padding-left: 0;
                      }
                    }
                  }

                  a {
                    padding-top: 0;
                    text-decoration: none;
                    padding-left: 5px;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    color: inherit;

                    &:hover {
                      color: $secondary-color;
                    }
                  }
                }
              }

              .tel {
                padding-bottom: 15px;

                p {
                  display: flex;
                  line-height: 18px;
                  font-weight: 400;

                  a {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    padding-top: 0;
                    text-decoration: none;
                    padding-left: 5px;

                    &:hover {
                      color: $secondary-color;
                    }
                  }
                }
              }

              .url {
                padding-bottom: 15px;

                a {
                  padding-left: 0 !important;

                  &:hover {
                    span {
                      color: $blue !important;
                    }

                    svg {
                      path {
                        fill: $blue !important;
                      }
                    }

                  }
                }

                p {
                  display: flex;
                  align-items: center;
                  @media (max-width: $screen-sm-max) {
                    flex-flow: row wrap;
                    a {
                      width: 100%;
                      margin-top: 3Px;
                    }
                  }

                  span {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    padding-top: 0;
                    text-decoration: none;
                    padding-left: 5px;
                    font-style: italic;
                    color: #707070;
                  }

                  a {
                    font-family: 'Arial', sans-serif;
                    font-size: 11px;
                    line-height: 12px;
                    font-weight: 400;
                    padding-top: 0;
                    text-decoration: none;
                    padding-left: 15px;
                    font-style: italic;
                    color: #707070;

                    &:hover {
                      color: $secondary-color;
                    }
                  }
                }
              }

              > p {
                font-weight: 400;
                line-height: 18px;
                text-align: left;

                br {
                  @media (max-width: $screen-sm-max) {
                    display: none;
                  }
                }

                &.marge-top {
                  margin-top: 15px;
                }

                .blue {
                  font-weight: 700;
                  padding-left: 5px;
                }

                strong {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}



