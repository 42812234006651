.hebergement {
  max-width: 100%;
  width: 100%;

  &-title {
    background: $hebergement-bandeau-background-color;
    color: $hebergement-bandeau-text-color;
  }

  &.hebergement-multiple {
    .hebergement-content {
      max-width: 1380px;
      margin: 0 auto;
      padding: 50px 0;
      @media (max-width: $screen-md-max) {
        padding: 50px 0;
        max-width: 800px;
      }
      @media (max-width: $screen-sm-max) {
        padding: 50px 15px;
      }
      @media (max-width: $screen-xs-max) {
        padding: 50px 0;
      }

      &-map {
        padding: 70px 15px 0px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &.hebergement-single {
    &.with-map {
      margin-bottom: 120px;
      @media (max-width: $screen-xs-max) {
        margin-bottom: 80px;
      }
    }

    .hebergement-content {
      max-width: 100%;
      margin: 0 auto;
      padding: 0;
      display: flex;
      flex-direction: column;


      &-map {
        width: 100%;
        padding: 70px 0 0;

        img {
          width: 100%;
          height: auto;
        }
      }

      .hotel-single-slider {
        .slick-arrow {
          &:before, &:after {
            color: $white;
          }
        }
      }
    }
  }
}
