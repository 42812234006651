.registration-participant {
  &-container {
    margin-top: 40px;
  }

  &-item {
    box-shadow: none;

    &-container {
      margin-bottom: 40px;

      .registration-participant-item {
        margin-top: 40px;
        border-radius: $form-block-radius;

        .registration-block {
          margin-top: 40px;
          border-radius: $form-block-radius;

          &-header {
            border-top-left-radius: $form-block-radius;
            border-top-right-radius: $form-block-radius;

            h2 {
              padding: 15px 70px;
              @media (max-width: $screen-xs-max) {
                padding: 15px 42px;
              }
            }
          }

          &.close .registration-block-header {
            border-bottom-left-radius: $form-block-radius;
            border-bottom-right-radius: $form-block-radius;
          }
        }
      }
    }
  }
}
