//// COMMONS
$registration-end-background: lightgray;

//// BANDEAU TITLE
$registration-end-bandeau-title-background: $gold;
$registration-end-bandeau-title-color: $black;
$registration-end-bandeau-title-color-without-bandeau: $black;
$registration-end-bandeau-title-border-without-bandeau: $gold;

//// WRAPPER
$registration-end-wrapper-max-width: 1600px;

//// CTA
$registration-end-cta-background: $dark-blue;
$registration-end-cta-color: $white;
$registration-end-cta-border: none;
$registration-end-cta-background-hover: $black;
$registration-end-cta-color-hover: $white;



