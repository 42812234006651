.payment-method-block {
  margin: 80px 0 0;
  border-radius: 20px;

  .registration-block {
    &-header {
      border-top-right-radius: $form-block-radius;
      border-top-left-radius: $form-block-radius;
    }

    &-content {

      .form-item-termsOfCancellation {
        margin-top: 20px;
      }

      .payment-method-block-details {
        p {
          margin-bottom: 40px;
        }
      }

      .form-item-termsOfCancellationWarning {
        margin-bottom: 30px;

        p {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;

          &.black {
            color: $black;
            padding-top: 10px;
          }
        }
      }

      .form-item-paymentMethod {
        margin-bottom: 10px;

        .form-item-choice-container {
          flex-direction: column;

          .form-item-choice {
            min-height: 18px;
            margin-left: 0 !important;
            margin-bottom: 20px;

            label {
              font-weight: bold;
            }

            p {
              padding: 6px 0 0 25px;
              font-size: 14px;
              line-height: 18px;
              color: $black;

            }
          }
        }
      }

      .terms-of-cancellation {
        p, ul li {
          color: $black;
        }

        p {
          font-weight: bold;
          padding-bottom: 10px;
        }
      }
    }
  }

}
